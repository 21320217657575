
import List from '@/components/ui/js/List'
import { computed } from 'vue'

export default {
  name: 'Weui_2.5.1List',
  props: {
    uiconfig: Object,
    pageid: String,
    dragableCss: Object
  },
  setup (props: any, context: any) {
    const list = new List(props, context)
    const uiCss = computed(() => {
      const css = list.getUICss()
      delete css.backgroundTheme
      delete css.foregroundTheme
      return Object.values(css).join(' ')
    })
    const uiStyle = computed(() => {
      const style = list.getUIStyle()
      delete style?.color
      delete style?.['background-color']
      return list.appendImportant(style)
    })
    const itemTheme = computed(() => {
      const css: any = ['']
      const cssMap = list.getUICss()
      if (cssMap?.backgroundTheme) css.push(cssMap?.backgroundTheme)
      if (cssMap?.foregroundTheme) css.push(cssMap?.foregroundTheme)
      return css.join(' ')
    })
    const itemStyle = computed(() => {
      const style = list.getUIStyle()
      const newStyle: any = []
      if (style?.color) {
        newStyle.push(`color:${style.color} !important`)
      }
      if (style?.['background-color']) {
        newStyle.push(`background-color:${style?.['background-color']} !important`)
        newStyle.push(`border-color:${style?.['background-color']} !important`)
      }
      return newStyle.join(';')
    })
    return {
      ...list.setup(),
      itemTheme,
      itemStyle,
      uiStyle,
      uiCss
    }
  }
}

