import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UIBase = _resolveComponent("UIBase")

  return (_openBlock(), _createBlock("div", {
    class: [$props.dragableCss, _ctx.uiCss],
    draggable: "false",
    style: $setup.uiStyle,
    id: $props.uiconfig.meta.id,
    "data-type": $props.uiconfig.type,
    "data-isContainer": !_ctx.inlineEditItemId,
    "data-pageid": $props.pageid
  }, [
    (_openBlock(true), _createBlock(_Fragment, null, _renderList($props.uiconfig.items, (item, index) => {
      return (_openBlock(), _createBlock(_component_UIBase, {
        key: index,
        uiconfig: item,
        pageid: $props.pageid
      }, null, 8, ["uiconfig", "pageid"]))
    }), 128))
  ], 14, ["id", "data-type", "data-isContainer", "data-pageid"]))
}