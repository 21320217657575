import { toDisplayString as _toDisplayString, withKeys as _withKeys, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Fragment, null, [
    (_ctx.type=='h1')
      ? (_openBlock(), _createBlock("h1", {
          key: 0,
          draggable: !_ctx.inlineEditItemId,
          onDblclick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.inlineEditItemId=$props.uiconfig.meta.id)),
          contenteditable: _ctx.inlineEditItemId==$props.uiconfig.meta.id,
          onKeyup: _cache[2] || (_cache[2] = _withKeys(($event: any) => (_ctx.inlineEditItemId=''), ["enter"])),
          class: [$props.dragableCss, $setup.myCss],
          style: $setup.myStyleText,
          id: $props.uiconfig.meta.id,
          "data-type": $props.uiconfig.type,
          "data-pageid": $props.pageid
        }, _toDisplayString($props.uiconfig.meta.value||$props.uiconfig.meta.title), 47, ["draggable", "contenteditable", "id", "data-type", "data-pageid"]))
      : _createCommentVNode("", true),
    (_ctx.type=='h2')
      ? (_openBlock(), _createBlock("h2", {
          key: 1,
          draggable: !_ctx.inlineEditItemId,
          onDblclick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.inlineEditItemId=$props.uiconfig.meta.id)),
          contenteditable: _ctx.inlineEditItemId==$props.uiconfig.meta.id,
          onKeyup: _cache[4] || (_cache[4] = _withKeys(($event: any) => (_ctx.inlineEditItemId=''), ["enter"])),
          class: [$props.dragableCss, $setup.myCss],
          style: $setup.myStyleText,
          id: $props.uiconfig.meta.id,
          "data-type": $props.uiconfig.type,
          "data-pageid": $props.pageid
        }, _toDisplayString($props.uiconfig.meta.value||$props.uiconfig.meta.title), 47, ["draggable", "contenteditable", "id", "data-type", "data-pageid"]))
      : _createCommentVNode("", true),
    (_ctx.type=='h3')
      ? (_openBlock(), _createBlock("h3", {
          key: 2,
          draggable: !_ctx.inlineEditItemId,
          onDblclick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.inlineEditItemId=$props.uiconfig.meta.id)),
          contenteditable: _ctx.inlineEditItemId==$props.uiconfig.meta.id,
          onKeyup: _cache[6] || (_cache[6] = _withKeys(($event: any) => (_ctx.inlineEditItemId=''), ["enter"])),
          class: [$props.dragableCss, $setup.myCss],
          style: $setup.myStyleText,
          id: $props.uiconfig.meta.id,
          "data-type": $props.uiconfig.type,
          "data-pageid": $props.pageid
        }, _toDisplayString($props.uiconfig.meta.value||$props.uiconfig.meta.title), 47, ["draggable", "contenteditable", "id", "data-type", "data-pageid"]))
      : _createCommentVNode("", true),
    (_ctx.type=='h4')
      ? (_openBlock(), _createBlock("h4", {
          key: 3,
          draggable: !_ctx.inlineEditItemId,
          onDblclick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.inlineEditItemId=$props.uiconfig.meta.id)),
          contenteditable: _ctx.inlineEditItemId==$props.uiconfig.meta.id,
          onKeyup: _cache[8] || (_cache[8] = _withKeys(($event: any) => (_ctx.inlineEditItemId=''), ["enter"])),
          class: [$props.dragableCss, $setup.myCss],
          style: $setup.myStyleText,
          id: $props.uiconfig.meta.id,
          "data-type": $props.uiconfig.type,
          "data-pageid": $props.pageid
        }, _toDisplayString($props.uiconfig.meta.value||$props.uiconfig.meta.title), 47, ["draggable", "contenteditable", "id", "data-type", "data-pageid"]))
      : _createCommentVNode("", true),
    (_ctx.type=='h5')
      ? (_openBlock(), _createBlock("h5", {
          key: 4,
          draggable: !_ctx.inlineEditItemId,
          onDblclick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.inlineEditItemId=$props.uiconfig.meta.id)),
          contenteditable: _ctx.inlineEditItemId==$props.uiconfig.meta.id,
          onKeyup: _cache[10] || (_cache[10] = _withKeys(($event: any) => (_ctx.inlineEditItemId=''), ["enter"])),
          class: [$props.dragableCss, $setup.myCss],
          style: $setup.myStyleText,
          id: $props.uiconfig.meta.id,
          "data-type": $props.uiconfig.type,
          "data-pageid": $props.pageid
        }, _toDisplayString($props.uiconfig.meta.value||$props.uiconfig.meta.title), 47, ["draggable", "contenteditable", "id", "data-type", "data-pageid"]))
      : _createCommentVNode("", true),
    (_ctx.type=='h6')
      ? (_openBlock(), _createBlock("h6", {
          key: 5,
          draggable: !_ctx.inlineEditItemId,
          onDblclick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.inlineEditItemId=$props.uiconfig.meta.id)),
          contenteditable: _ctx.inlineEditItemId==$props.uiconfig.meta.id,
          onKeyup: _cache[12] || (_cache[12] = _withKeys(($event: any) => (_ctx.inlineEditItemId=''), ["enter"])),
          class: [$props.dragableCss, $setup.myCss],
          style: $setup.myStyleText,
          id: $props.uiconfig.meta.id,
          "data-type": $props.uiconfig.type,
          "data-pageid": $props.pageid
        }, _toDisplayString($props.uiconfig.meta.value||$props.uiconfig.meta.title), 47, ["draggable", "contenteditable", "id", "data-type", "data-pageid"]))
      : _createCommentVNode("", true),
    (_ctx.type=='p')
      ? (_openBlock(), _createBlock("p", {
          key: 6,
          draggable: !_ctx.inlineEditItemId,
          onDblclick: _cache[13] || (_cache[13] = ($event: any) => (_ctx.inlineEditItemId=$props.uiconfig.meta.id)),
          contenteditable: _ctx.inlineEditItemId==$props.uiconfig.meta.id,
          onKeyup: _cache[14] || (_cache[14] = _withKeys(($event: any) => (_ctx.inlineEditItemId=''), ["enter"])),
          class: [$props.dragableCss, $setup.myCss],
          style: $setup.myStyleText,
          id: $props.uiconfig.meta.id,
          "data-type": $props.uiconfig.type,
          "data-pageid": $props.pageid
        }, _toDisplayString($props.uiconfig.meta.value||$props.uiconfig.meta.title), 47, ["draggable", "contenteditable", "id", "data-type", "data-pageid"]))
      : _createCommentVNode("", true),
    (_ctx.type=='span')
      ? (_openBlock(), _createBlock("div", {
          key: 7,
          draggable: !_ctx.inlineEditItemId,
          onDblclick: _cache[15] || (_cache[15] = ($event: any) => (_ctx.inlineEditItemId=$props.uiconfig.meta.id)),
          contenteditable: _ctx.inlineEditItemId==$props.uiconfig.meta.id,
          onKeyup: _cache[16] || (_cache[16] = _withKeys(($event: any) => (_ctx.inlineEditItemId=''), ["enter"])),
          class: [$props.dragableCss, $setup.myCss],
          style: $setup.myStyleText,
          id: $props.uiconfig.meta.id,
          "data-type": $props.uiconfig.type,
          "data-pageid": $props.pageid
        }, _toDisplayString($props.uiconfig.meta.value||$props.uiconfig.meta.title), 47, ["draggable", "contenteditable", "id", "data-type", "data-pageid"]))
      : _createCommentVNode("", true)
  ], 64))
}