import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormGroup = _resolveComponent("FormGroup")

  return (_openBlock(), _createBlock(_component_FormGroup, {
    uiconfig: $props.uiconfig,
    pageid: $props.pageid,
    draggable: !_ctx.inlineEditItemId,
    dragableCss: $props.dragableCss
  }, {
    default: _withCtx(() => [
      _createVNode("select", {
        multiple: _ctx.isMultiple,
        placeholder: $props.uiconfig.meta?.form?.placeholder,
        disabled: $props.uiconfig.meta?.form?.state==='disabled',
        readonly: $props.uiconfig.meta?.form?.state==='readonly',
        required: $props.uiconfig.meta?.form?.required,
        id: $props.uiconfig.meta.id+$props.uiconfig.type,
        style: $setup.bodyStyle,
        class: $setup.bodyCss,
        name: $props.uiconfig.meta?.form?.inputName
      }, [
        (_openBlock(true), _createBlock(_Fragment, null, _renderList($props.uiconfig.meta.values, (item, index) => {
          return (_openBlock(), _createBlock("option", {
            selected: item.checked,
            key: index,
            value: item.value
          }, _toDisplayString(item.text), 9, ["selected", "value"]))
        }), 128))
      ], 14, ["multiple", "placeholder", "disabled", "readonly", "required", "id", "name"])
    ]),
    _: 1
  }, 8, ["uiconfig", "pageid", "draggable", "dragableCss"]))
}