import { toDisplayString as _toDisplayString, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "layui-font-gray"
}
const _hoisted_2 = { class: "layui-mr-auto" }
const _hoisted_3 = {
  key: 0,
  class: "layui-font-gray"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UIBase = _resolveComponent("UIBase")

  return (_openBlock(), _createBlock("div", {
    draggable: !_ctx.inlineEditItemId,
    class: ['layui-custom-nav layui-nav',$props.dragableCss, _ctx.uiCss],
    style: _ctx.uiStyle,
    id: $props.uiconfig.meta.id,
    "data-type": $props.uiconfig.type,
    "data-isContainer": "true",
    "data-pageid": $props.pageid
  }, [
    _createVNode("div", {
      class: ['subui',{'dragenter-subcontainer': _ctx.isDragIn && _ctx.dragoverInParent=='brand'}],
      "data-placeInParent": "brand"
    }, [
      (!_ctx.myItems.brand.length)
        ? (_openBlock(), _createBlock("span", _hoisted_1, _toDisplayString(_ctx.t('common.dragtohere')), 1))
        : _createCommentVNode("", true),
      (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.myItems.brand, (item, index) => {
        return (_openBlock(), _createBlock(_component_UIBase, {
          key: index,
          uiconfig: item,
          pageid: $props.pageid
        }, null, 8, ["uiconfig", "pageid"]))
      }), 128))
    ], 2),
    _createVNode("div", _hoisted_2, [
      (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.values, (item, index) => {
        return (_openBlock(), _createBlock("div", {
          class: [{'layui-nav-item': true, 'layui-this': item.checked}],
          key: index
        }, [
          _createVNode("a", {
            href: "javascript:;",
            style: $setup.menuStyle,
            class: $setup.menuCss
          }, _toDisplayString(item.text), 7)
        ], 2))
      }), 128)),
      (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.myItems.main, (item, index) => {
        return (_openBlock(), _createBlock(_component_UIBase, {
          key: index,
          uiconfig: item,
          pageid: $props.pageid
        }, null, 8, ["uiconfig", "pageid"]))
      }), 128))
    ]),
    _createVNode("div", {
      class: ['subui', {'dragenter-subcontainer': _ctx.isDragIn && _ctx.dragoverInParent=='right'}],
      "data-placeInParent": "right"
    }, [
      (!_ctx.myItems.right.length)
        ? (_openBlock(), _createBlock("span", _hoisted_3, _toDisplayString(_ctx.t('common.dragtohere')), 1))
        : _createCommentVNode("", true),
      (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.myItems.right, (item, index) => {
        return (_openBlock(), _createBlock(_component_UIBase, {
          key: index,
          uiconfig: item,
          pageid: $props.pageid
        }, null, 8, ["uiconfig", "pageid"]))
      }), 128))
    ], 2)
  ], 14, ["draggable", "id", "data-type", "data-pageid"]))
}