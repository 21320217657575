
import Select from '@/components/ui/js/Select'
import BootstrapFormGroup from '@/components/ui/layui_2.6.7/FormGroup.vue'
import { computed } from 'vue'

export default {
  name: 'Layui_2.6.7Select',
  props: {
    uiconfig: Object,
    pageid: String,
    dragableCss: Object
  },
  components: { BootstrapFormGroup },
  setup (props: any, context: any) {
    const select = new Select(props, context)
    const bodyCss = computed(() => {
      const arr: any = ['layui-unselect layui-form-select']
      if (props.uiconfig.meta?.css?.formSizing && props.uiconfig.meta?.css?.formSizing !== 'normal') {
        arr.push('layui-form-' + props.uiconfig.meta.css.formSizing)
      }
      return arr
    })

    const value = computed(() => {
      if (!props.uiconfig.meta.values) return ''
      for (const item of props.uiconfig.meta.values) {
        if (item.checked) return item.text
      }
      return ''
    })
    return {
      ...select.setup(),
      bodyCss,
      value
    }
  }
}

