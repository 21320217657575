import { createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BootstrapFormGroup = _resolveComponent("BootstrapFormGroup")

  return (_openBlock(), _createBlock(_component_BootstrapFormGroup, {
    uiconfig: $props.uiconfig,
    pageid: $props.pageid,
    draggable: !_ctx.inlineEditItemId,
    dragableCss: $props.dragableCss
  }, {
    default: _withCtx(() => [
      _createVNode("div", {
        class: $setup.bodyCss,
        style: $setup.bodyStyle
      }, [
        _createVNode("input", {
          type: "file",
          id: $props.uiconfig.meta.id+$props.uiconfig.type,
          class: "d-block",
          disabled: $props.uiconfig.meta?.form?.state==='disabled',
          readonly: $props.uiconfig.meta?.form?.state==='readonly',
          required: $props.uiconfig.meta?.form?.required,
          name: $props.uiconfig.meta?.form?.inputName,
          accept: $props.uiconfig.meta?.custom?.accept || '*/*',
          multiple: $props.uiconfig.meta?.custom?.multiple
        }, null, 8, ["id", "disabled", "readonly", "required", "name", "accept", "multiple"])
      ], 6)
    ]),
    _: 1
  }, 8, ["uiconfig", "pageid", "draggable", "dragableCss"]))
}