import UIBase from '@/components/ui/js/UIBase'

export default class Container extends UIBase {
  setup () {
    const superSetup = super.setup()
    return {
      ...superSetup
    }
  }
}
