import { createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("img", {
    draggable: !_ctx.inlineEditItemId,
    class: [$props.dragableCss, _ctx.uiCss],
    style: _ctx.uiStyle,
    id: $props.uiconfig.meta.id,
    "data-type": $props.uiconfig.type,
    "data-pageid": $props.pageid,
    src: $props.uiconfig.meta.value||'/logo.jpg',
    alt: $props.uiconfig.meta.title
  }, null, 14, ["draggable", "id", "data-type", "data-pageid", "src", "alt"]))
}