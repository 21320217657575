import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("ol", {
    draggable: !_ctx.inlineEditItemId,
    class: ['breadcrumb', $setup.uiCss, $props.dragableCss],
    style: $setup.uiStyle,
    id: $props.uiconfig.meta.id,
    "data-type": $props.uiconfig.type,
    "data-pageid": $props.pageid
  }, [
    (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.values, (item, index) => {
      return (_openBlock(), _createBlock("li", {
        class: {'breadcrumb-item': true, 'active':item.checked},
        key: index
      }, [
        (!item.checked)
          ? (_openBlock(), _createBlock("a", {
              key: 0,
              href: item.value,
              class: $setup.foregroundCss,
              style: $setup.foregroundStyle
            }, _toDisplayString(item.text), 15, ["href"]))
          : _createCommentVNode("", true),
        (item.checked)
          ? (_openBlock(), _createBlock(_Fragment, { key: 1 }, [
              _createTextVNode(_toDisplayString(item.text), 1)
            ], 64))
          : _createCommentVNode("", true)
      ], 2))
    }), 128))
  ], 14, ["draggable", "id", "data-type", "data-pageid"]))
}