import { toDisplayString as _toDisplayString, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, renderSlot as _renderSlot, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "weui-label" }
const _hoisted_2 = {
  key: 0,
  class: "text-danger"
}
const _hoisted_3 = { class: "weui-cell__bd" }
const _hoisted_4 = {
  key: 0,
  class: "text-danger"
}
const _hoisted_5 = { class: "weui-cell " }
const _hoisted_6 = { class: "weui-cell__bd" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", {
    draggable: !_ctx.inlineEditItemId,
    style: $setup.uiStyle,
    id: $props.uiconfig.meta.id,
    "data-type": $props.uiconfig.type,
    "data-pageid": $props.pageid,
    class: [$props.dragableCss, $setup.uiCss]
  }, [
    ($setup.isHorizontal)
      ? (_openBlock(), _createBlock("div", {
          key: 0,
          class: $setup.singleLineCss
        }, [
          (_ctx.hasTitle)
            ? (_openBlock(), _createBlock("div", {
                key: 0,
                class: $setup.labelCss
              }, [
                _createVNode("span", _hoisted_1, [
                  _createTextVNode(_toDisplayString($props.uiconfig.meta.title), 1),
                  ($props.uiconfig.meta?.form?.required)
                    ? (_openBlock(), _createBlock("span", _hoisted_2, "*"))
                    : _createCommentVNode("", true)
                ])
              ], 2))
            : _createCommentVNode("", true),
          _createVNode("div", _hoisted_3, [
            _createVNode("div", { class: $setup.bodyCss }, [
              _renderSlot(_ctx.$slots, "default")
            ], 2),
            (_ctx.hasHelpTip)
              ? (_openBlock(), _createBlock("small", {
                  key: 0,
                  id: $props.uiconfig.meta.id+'Help',
                  class: "text-muted"
                }, _toDisplayString($props.uiconfig.meta?.form?.helpTip), 9, ["id"]))
              : _createCommentVNode("", true)
          ])
        ], 2))
      : (_openBlock(), _createBlock(_Fragment, { key: 1 }, [
          (_ctx.hasTitle)
            ? (_openBlock(), _createBlock("div", {
                key: 0,
                class: $setup.labelCss
              }, [
                _createTextVNode(_toDisplayString($props.uiconfig.meta.title), 1),
                ($props.uiconfig.meta?.form?.required)
                  ? (_openBlock(), _createBlock("span", _hoisted_4, "*"))
                  : _createCommentVNode("", true)
              ], 2))
            : _createCommentVNode("", true),
          _createVNode("div", {
            class: "weui-cells weui-cells_form",
            style: $setup.boxStyle
          }, [
            _createVNode("div", _hoisted_5, [
              _createVNode("div", _hoisted_6, [
                _createVNode("div", { class: $setup.bodyCss }, [
                  _renderSlot(_ctx.$slots, "default")
                ], 2),
                (_ctx.hasHelpTip)
                  ? (_openBlock(), _createBlock("small", {
                      key: 0,
                      id: $props.uiconfig.meta.id+'Help',
                      class: "text-muted"
                    }, _toDisplayString($props.uiconfig.meta?.form?.helpTip), 9, ["id"]))
                  : _createCommentVNode("", true)
              ])
            ])
          ], 4)
        ], 64))
  ], 14, ["draggable", "id", "data-type", "data-pageid"]))
}