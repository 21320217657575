import { createVNode as _createVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "weui-slider-box w-100" }
const _hoisted_2 = { class: "weui-slider pl-0" }
const _hoisted_3 = { class: "weui-slider-box__value" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormGroup = _resolveComponent("FormGroup")

  return (_openBlock(), _createBlock(_component_FormGroup, {
    uiconfig: $props.uiconfig,
    pageid: $props.pageid,
    draggable: !_ctx.inlineEditItemId,
    dragableCss: $props.dragableCss
  }, {
    default: _withCtx(() => [
      _createVNode("div", _hoisted_1, [
        _createVNode("div", _hoisted_2, [
          _createVNode("div", {
            style: $setup.bgStyle,
            class: $setup.bgTheme
          }, [
            _createVNode("div", {
              style: $setup.trackStyle,
              class: $setup.trackTheme
            }, null, 6),
            _createVNode("div", {
              style: $setup.handleStyle,
              class: $setup.handleTheme
            }, null, 6)
          ], 6)
        ]),
        _createVNode("div", _hoisted_3, _toDisplayString($props.uiconfig.meta.value||50), 1)
      ])
    ]),
    _: 1
  }, 8, ["uiconfig", "pageid", "draggable", "dragableCss"]))
}