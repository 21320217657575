
import { computed } from 'vue'

export default {
  name: 'IconWrapper',
  props: {
    uiconfig: Object
  },
  setup (props: any, context: any) {
    const icon = computed(() => props.uiconfig.meta?.custom?.icon)
    const position = computed(() => props.uiconfig.meta?.custom?.['icon-position'])
    return {
      icon,
      position
    }
  }
}
