
import { computed } from 'vue'
import Dropdown from '@/components/ui/js/Dropdown'
import { useStore } from 'vuex'
import IconWrapper from '@/components/ui/bootstrap_4.6.0/IconWrapper.vue'

export default {
  name: 'Layui_2.6.7Dropdown',
  components: { IconWrapper },
  props: {
    uiconfig: Object,
    pageid: String,
    dragableCss: Object
  },
  setup (props: any, context: any) {
    const dropdown = new Dropdown(props, context)
    const setup = dropdown.setup()
    const { parentUi, parentIsNavbar, parentIsButtonGroup } = setup
    const store = useStore()

    // 如果上层是按钮，那么继承他的outline，size属性
    const dropdownMeta = computed(() => parentIsButtonGroup.value || parentIsNavbar.value ? parentUi.value.meta : props.uiconfig.meta)
    const sizing = computed(() => {
      let sizingContext = ''
      let sizing = ''
      if (parentIsButtonGroup.value) { // 如果父容器是按钮组
        sizing = dropdownMeta.value?.css?.buttonSizing
        sizingContext = 'buttonSizing'
      } else {
        sizing = dropdownMeta.value?.css?.dropdownSizing
        sizingContext = 'dropdownSizing'
      }
      return store.getters.translate(sizingContext, sizing)
    })
    const selfHasForeground = computed(() => props.uiconfig.meta?.css?.foregroundTheme && props.uiconfig.meta?.css?.foregroundTheme !== 'default')
    const selfHasBackground = computed(() => props.uiconfig.meta?.css?.backgroundTheme && props.uiconfig.meta?.css?.backgroundTheme !== 'default')
    const theme = computed(() => {
      // 如果自己有背景和前景则用自己的，否则用上层的，如buttongroup
      let myBackgruondTheme = props.uiconfig.meta?.css?.backgroundTheme !== 'default' ? props.uiconfig.meta?.css?.backgroundTheme : ''
      myBackgruondTheme = myBackgruondTheme || dropdownMeta.value?.css?.backgroundTheme
      return myBackgruondTheme === 'default' ? '' : myBackgruondTheme
    })
    const foreTheme = computed(() => {
      // 如果按钮有背景和前景则用按钮的，否则用上层的buttongroup
      let foregroundTheme = props.uiconfig.meta?.css?.foregroundTheme !== 'default' ? props.uiconfig.meta?.css?.foregroundTheme : ''
      foregroundTheme = foregroundTheme || dropdownMeta.value?.css?.foregroundTheme
      return foregroundTheme === 'default' ? '' : foregroundTheme
    })

    const btnCss = computed(() => {
      const arr: any = []
      if (parentIsNavbar.value) {
        if (foreTheme.value) {
          arr.push(store.getters.translate('foregroundTheme', foreTheme.value))
        }
        if (theme.value) {
          arr.push(store.getters.translate('backgroundTheme', theme.value))
        }
        arr.push('layui-nav-item')
        return arr.join(' ')
      }
      arr.push('layui-btn')

      if (dropdownMeta.value.custom?.isOutline) {
        arr.push('layui-btn-primary')
        const border = store.getters.translate('borderColorClass', theme.value)
        arr.push(border)
      }
      if (sizing.value) {
        arr.push(sizing.value)
      }
      if (foreTheme.value) {
        arr.push(store.getters.translate('foregroundTheme', foreTheme.value))
      }
      if (theme.value) {
        arr.push(store.getters.translate('backgroundTheme', theme.value))
      }
      return arr.join(' ')
    })

    const uiCss = computed(() => {
      const css = dropdown.getUICss()
      const arr: any = []
      if (props.uiconfig.meta?.custom?.isSplit) {
        delete css.borderColorClass
        delete css.dropdownSizing
        delete css.backgroundTheme
      }
      arr.push(...Object.values(css))
      if (parentIsNavbar.value) {
        arr.push('layui-nav-item')
      }
      return arr.join(' ')
    })
    const splitBtnCss = computed(() => {
      const css = ['layui-ml-0 layui-rounded-right layui-pl-1 layui-pr-1']
      return css.join(' ')
    })
    const splitBtnStyle = computed(() => {
      const style = dropdown.getUIStyle()
      // 分割线颜色
      let color = '#fff'
      if (style['border-color']) {
        color = style['border-color']
      }
      return btnStyle.value + `;border-left-color: ${color}!important`
    })
    const btnStyle = computed(() => {
      const style = dropdown.getUIStyle()
      // 如果自己有背景和前景则用自己的，否则用上层的，如buttongroup
      const color = style?.color || dropdownMeta.value?.style?.color
      const backgroundColor = style?.['background-color'] || dropdownMeta.value?.style?.['background-color']
      if (!selfHasForeground.value && color) { // 有css时用预定义css
        style.color = color
      }
      if (!selfHasBackground.value && backgroundColor) {
        style['background-color'] = backgroundColor
        if (!style['border-color']) {
          style['border-color'] = backgroundColor
        }
      }
      // console.log(arr)
      return dropdown.appendImportant(style)
    })
    const uiStyle = computed(() => {
      const style = dropdown.getUIStyle()
      // 如果不是分体按钮，那么需要考虑属性的继承性；如果是分体按钮，属性的继承由btnStyle处理
      if (props.uiconfig.meta?.custom?.isSplit) {
        delete style['background-color']
        for (const key in style) {
          if (key.match(/^border-/)) {
            delete style[key]
          }
        }
        return dropdown.appendImportant(style)
      }
      // 如果自己有背景和前景则用自己的，否则用上层的，如buttongroup
      const color = style?.color || dropdownMeta.value?.style?.color
      const backgroundColor = style?.['background-color'] || dropdownMeta.value?.style?.['background-color']
      if (!selfHasForeground.value && color) { // 有css时用预定义css
        style.color = color
      }
      if (!selfHasBackground.value && backgroundColor) {
        style['background-color'] = backgroundColor
        if (!style['border-color']) {
          style['border-color'] = backgroundColor
        }
      }
      // console.log(arr)
      return dropdown.appendImportant(style)
    })
    return {
      ...setup,
      splitBtnCss,
      splitBtnStyle,
      btnCss,
      btnStyle,
      uiCss,
      uiStyle
    }
  }
}

