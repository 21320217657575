import { createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BootstrapFormGroup = _resolveComponent("BootstrapFormGroup")

  return (_openBlock(), _createBlock(_component_BootstrapFormGroup, {
    uiconfig: $props.uiconfig,
    pageid: $props.pageid,
    draggable: !_ctx.inlineEditItemId,
    dragableCss: $props.dragableCss
  }, {
    default: _withCtx(() => [
      _createVNode("div", {
        class: $setup.bodyCss,
        style: $setup.bodyStyle
      }, [
        _createVNode("input", {
          type: "range",
          id: $props.uiconfig.meta.id+$props.uiconfig.type,
          class: $setup.rangeCss,
          style: $setup.rangeStyle,
          name: $props.uiconfig.meta?.form?.inputName,
          placeholder: $props.uiconfig.meta.title,
          disabled: $props.uiconfig.meta?.form?.state==='disabled',
          readonly: $props.uiconfig.meta?.form?.state==='readonly',
          min: $props.uiconfig.meta.custom?.min||1,
          max: $props.uiconfig.meta.custom?.max||100,
          step: $props.uiconfig.meta.custom?.step||1,
          required: $props.uiconfig.meta?.form?.required,
          value: $props.uiconfig.meta.value||50
        }, null, 14, ["id", "name", "placeholder", "disabled", "readonly", "min", "max", "step", "required", "value"])
      ], 6)
    ]),
    _: 1
  }, 8, ["uiconfig", "pageid", "draggable", "dragableCss"]))
}