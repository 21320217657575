
import FormGroup from '@/components/ui/weui_2.5.1/FormGroup.vue'
import Input from '@/components/ui/js/Input'
import IconWrapper from '@/components/ui/weui_2.5.1/IconWrapper.vue'

export default {
  name: 'Weui_2.5.1Input',
  components: { IconWrapper, FormGroup },
  props: {
    uiconfig: Object,
    pageid: String,
    dragableCss: Object
  },
  setup (props: any, context: any) {
    const input = new Input(props, context)
    return {
      ...input.setup()
    }
  }
}

