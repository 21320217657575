import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UIBase = _resolveComponent("UIBase")

  return (!$props.uiconfig.meta.custom?.type || $props.uiconfig.meta.custom?.type=='normal')
    ? (_openBlock(), _createBlock("div", {
        key: 0,
        draggable: !_ctx.inlineEditItemId,
        class: ['layui-tab layui-tab-brief',$props.dragableCss, $setup.uiCss],
        style: _ctx.uiStyle + ';visibility: visible !important;',
        id: $props.uiconfig.meta.id,
        "data-type": $props.uiconfig.type,
        "data-isContainer": true,
        "data-pageid": $props.pageid
      }, [
        _createVNode("ul", { class: $setup.tabBodyCss }, [
          (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.values, (item, index) => {
            return (_openBlock(), _createBlock("li", {
              class: ['layui-nav-item',{'layui-this': item.checked}, $setup.foreTheme],
              style: $setup.foreStyle,
              key: index
            }, _toDisplayString(item.text), 7))
          }), 128)),
          (_openBlock(true), _createBlock(_Fragment, null, _renderList($props.uiconfig.items, (item, index) => {
            return (_openBlock(), _createBlock("li", {
              class: ['layui-nav-item',$setup.foreTheme],
              key: index,
              style: $setup.foreStyle
            }, [
              _createVNode(_component_UIBase, {
                uiconfig: item,
                pageid: $props.pageid
              }, null, 8, ["uiconfig", "pageid"])
            ], 6))
          }), 128))
        ], 2)
      ], 14, ["draggable", "id", "data-type", "data-pageid"]))
    : ($props.uiconfig.meta.custom?.type==='tab')
      ? (_openBlock(), _createBlock("div", {
          key: 1,
          draggable: !_ctx.inlineEditItemId,
          class: ['layui-tab',$props.dragableCss, $setup.uiCss],
          style: _ctx.uiStyle,
          id: $props.uiconfig.meta.id,
          "data-type": $props.uiconfig.type,
          "data-isContainer": true,
          "data-pageid": $props.pageid
        }, [
          _createVNode("ul", { class: $setup.tabBodyCss }, [
            (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.values, (item, index) => {
              return (_openBlock(), _createBlock("li", {
                class: ['layui-nav-item',{'layui-this': item.checked}, $setup.foreTheme],
                style: $setup.foreStyle,
                key: index
              }, _toDisplayString(item.text), 7))
            }), 128)),
            (_openBlock(true), _createBlock(_Fragment, null, _renderList($props.uiconfig.items, (item, index) => {
              return (_openBlock(), _createBlock("li", {
                class: ['layui-nav-item',$setup.foreTheme],
                key: index,
                style: $setup.foreStyle
              }, [
                _createVNode(_component_UIBase, {
                  uiconfig: item,
                  pageid: $props.pageid
                }, null, 8, ["uiconfig", "pageid"])
              ], 6))
            }), 128))
          ], 2)
        ], 14, ["draggable", "id", "data-type", "data-pageid"]))
      : (_openBlock(), _createBlock("div", {
          key: 2,
          draggable: !_ctx.inlineEditItemId,
          class: [$props.dragableCss, $setup.uiCss],
          style: _ctx.uiStyle,
          id: $props.uiconfig.meta.id,
          "data-type": $props.uiconfig.type,
          "data-isContainer": true,
          "data-pageid": $props.pageid
        }, [
          (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.values, (item, index) => {
            return (_openBlock(), _createBlock("button", {
              type: "button",
              class: ['layui-nav-item layui-btn', item.checked ? $setup.pillCheckedTheme : $setup.foreTheme + ' layui-btn-primary layui-border-0 '],
              key: index,
              style: $setup.foreStyle
            }, _toDisplayString(item.text), 7))
          }), 128)),
          (_openBlock(true), _createBlock(_Fragment, null, _renderList($props.uiconfig.items, (item, index) => {
            return (_openBlock(), _createBlock("div", {
              class: ['layui-nav-item layui-d-flex layui-align-items-center layui-justify-content-center', $setup.foreTheme],
              key: index,
              style: $setup.foreStyle
            }, [
              _createVNode(_component_UIBase, {
                uiconfig: item,
                pageid: $props.pageid
              }, null, 8, ["uiconfig", "pageid"])
            ], 6))
          }), 128))
        ], 14, ["draggable", "id", "data-type", "data-pageid"]))
}