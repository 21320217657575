
import { computed } from 'vue'
import Navbar from '@/components/ui/js/Navbar'

export default {
  name: 'Weui_2.5.1Navbar',
  props: {
    uiconfig: Object,
    pageid: String,
    dragableCss: Object
  },
  setup (props: any, context: any) {
    const navbar = new Navbar(props, context)
    const setup = navbar.setup()
    const uiCss = computed(() => {
      const css = navbar.getUICss()
      return Object.values(css).join(' ')
    })
    const uiStyle = computed(() => {
      const style = navbar.getUIStyle()
      return navbar.appendImportant(style)
    })
    const activeItemCss = computed(() => {
      const css = navbar.getUICss()
      return css.foregroundTheme || 'text-success'
    })
    const activeItemStyle = computed(() => {
      const style = navbar.getUIStyle()
      return `color:${style.color} !important`
    })
    return {
      ...setup,
      uiCss,
      uiStyle,
      activeItemCss,
      activeItemStyle
    }
  }
}

