import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", {
    draggable: !_ctx.inlineEditItemId,
    class: ['weui-navbar',$props.dragableCss, $setup.uiCss],
    style: $setup.uiStyle,
    id: $props.uiconfig.meta.id,
    "data-type": $props.uiconfig.type,
    "data-pageid": $props.pageid
  }, [
    (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.values, (item, index) => {
      return (_openBlock(), _createBlock("div", {
        class: ['weui-navbar__item', item.checked ? $setup.activeItemCss : $setup.itemCss],
        key: index,
        style: item.checked ? $setup.activeItemStyle : $setup.itemStyle
      }, _toDisplayString(item.text), 7))
    }), 128))
  ], 14, ["draggable", "id", "data-type", "data-pageid"]))
}