
import Textarea from '@/components/ui/js/Textarea'
import BootstrapFormGroup from '@/components/ui/bootstrap_4.6.0/FormGroup.vue'
import { computed } from 'vue'

export default {
  name: 'Bootstrap_4.6.0Textarea',
  props: {
    uiconfig: Object,
    pageid: String,
    dragableCss: Object
  },
  components: { BootstrapFormGroup },
  setup (props: any, context: any) {
    const textarea = new Textarea(props, context)
    const bodyCss = computed(() => {
      const arr = ['form-control d-flex justify-content-between align-items-end h-auto']
      if (props.uiconfig.meta?.css?.formSizing && props.uiconfig.meta?.css?.formSizing !== 'normal') {
        arr.push('form-control-' + props.uiconfig.meta.css.formSizing)
      }
      if (props.uiconfig.meta?.form?.state === 'disabled') {
        arr.push('disabled')
      }
      if (props.uiconfig.meta?.form?.state === 'readonly') {
        arr.push('readonly')
      }
      return arr
    })
    const bodyStyle = computed(() => {
      const style = textarea.getUIStyle()
      const newStyle = {}
      for (const styleKey in style) {
        if (styleKey.match(/height/)) {
          newStyle[styleKey] = style[styleKey]
        }
      }
      return textarea.appendImportant(newStyle)
    })
    return {
      ...textarea.setup(),
      bodyStyle,
      bodyCss
    }
  }
}

