
import UIBase from '@/components/ui/UIBase.vue'
import Button from '@/components/ui/js/Button'
import { computed } from 'vue'
import baseUIDefines from '@/components/ui/define'

export default {
  name: 'Bootstrap_4.6.0ButtonGroup',
  components: { UIBase },
  props: {
    uiconfig: Object,
    pageid: String,
    dragableCss: Object
  },
  setup (props: any, context: any) {
    const button = new Button(props, context)
    const baseButton = button.setup()

    const buttonGroupCss = computed(() => {
      const css = button.getUICss()
      // 这几样应用在内部元素上
      delete css.backgroundTheme
      delete css.foregroundTheme
      delete css.buttonSizing
      const raw:any = Object.values(css)
      return raw.join(' ')
    })

    const buttonGroupStyle = computed(() => {
      const style = button.getUIStyle()
      // 这几样应用在内部元素上
      delete style.color
      delete style['background-color']
      return button.appendImportant(style)
    })

    let allowItem: any = []
    for (const type of (baseUIDefines.ButtonGroup?.subItemType || [])) {
      allowItem.push(baseButton.t(baseUIDefines[type]?.name))
    }
    allowItem = allowItem.join(',')

    return {
      ...baseButton,
      buttonGroupCss,
      buttonGroupStyle,
      allowItem
    }
  }
}
