import { renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (!$setup.icon)
    ? _renderSlot(_ctx.$slots, "default", { key: 0 })
    : (_openBlock(), _createBlock(_Fragment, { key: 1 }, [
        ($setup.position==='top')
          ? (_openBlock(), _createBlock(_Fragment, { key: 0 }, [
              _createVNode("div", null, [
                _createVNode("i", { class: $setup.icon }, null, 2)
              ]),
              _renderSlot(_ctx.$slots, "default")
            ], 64))
          : ($setup.position==='bottom')
            ? (_openBlock(), _createBlock(_Fragment, { key: 1 }, [
                _renderSlot(_ctx.$slots, "default"),
                _createVNode("div", null, [
                  _createVNode("i", { class: $setup.icon }, null, 2)
                ])
              ], 64))
            : ($setup.position==='right')
              ? (_openBlock(), _createBlock(_Fragment, { key: 2 }, [
                  _renderSlot(_ctx.$slots, "default"),
                  _createVNode("i", { class: $setup.icon }, null, 2)
                ], 64))
              : (_openBlock(), _createBlock(_Fragment, { key: 3 }, [
                  _createVNode("i", { class: $setup.icon }, null, 2),
                  _renderSlot(_ctx.$slots, "default")
                ], 64))
      ], 64))
}