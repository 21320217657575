import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", {
    draggable: !_ctx.inlineEditItemId,
    class: [$props.dragableCss, $setup.uiCss],
    style: $setup.uiStyle,
    id: $props.uiconfig.meta.id,
    "data-type": $props.uiconfig.type,
    "data-pageid": $props.pageid
  }, [
    _createVNode("table", {
      class: ['table', $props.dragableCss, $setup.tableCss],
      style: $setup.tableStyle
    }, [
      (!$props.uiconfig.meta.custom?.headless)
        ? (_openBlock(), _createBlock("thead", {
            key: 0,
            class: $setup.headerCss,
            style: $setup.headerStyle
          }, [
            _createVNode("tr", null, [
              (_openBlock(true), _createBlock(_Fragment, null, _renderList($setup.header, (item, index) => {
                return (_openBlock(), _createBlock("th", {
                  class: [$setup.alignCss],
                  key: index,
                  innerHTML: item.text
                }, null, 10, ["innerHTML"]))
              }), 128))
            ])
          ], 6))
        : _createCommentVNode("", true),
      _createVNode("tbody", null, [
        (_openBlock(true), _createBlock(_Fragment, null, _renderList($setup.row, (r, rindex) => {
          return (_openBlock(), _createBlock("tr", { key: rindex }, [
            (_openBlock(true), _createBlock(_Fragment, null, _renderList(r, (item, cindex) => {
              return (_openBlock(), _createBlock("td", {
                class: [$setup.alignCss],
                key: cindex,
                innerHTML: item.text
              }, null, 10, ["innerHTML"]))
            }), 128))
          ]))
        }), 128))
      ]),
      (!$props.uiconfig.meta.custom?.footless)
        ? (_openBlock(), _createBlock("tfoot", {
            key: 1,
            class: $setup.footerCss,
            style: $setup.footerStyle
          }, [
            _createVNode("tr", null, [
              (_openBlock(true), _createBlock(_Fragment, null, _renderList($setup.footer, (item, index) => {
                return (_openBlock(), _createBlock("th", {
                  class: [$setup.alignCss],
                  key: index,
                  innerHTML: item.text
                }, null, 10, ["innerHTML"]))
              }), 128))
            ])
          ], 6))
        : _createCommentVNode("", true)
    ], 6)
  ], 14, ["draggable", "id", "data-type", "data-pageid"]))
}