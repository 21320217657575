
import Page from '@/components/ui/js/Page'
import UIBase from '@/components/ui/UIBase.vue'
import { onMounted } from 'vue'

export default {
  name: 'Weui_2.5.1Page',
  components: { UIBase },
  props: {
    uiconfig: Object,
    pageid: String,
    dragableCss: Object
  },
  setup (props: any, context: any) {
    const page = new Page(props, context)
    onMounted(() => {
      document.body.setAttribute('data-weui-theme', 'light')
    })
    return {
      ...page.setup()
    }
  }
}

