
import Radio from '@/components/ui/js/Radio'
import BootstrapFormGroup from '@/components/ui/bootstrap_4.6.0/FormGroup.vue'
import { computed } from 'vue'

export default {
  name: 'Bootstrap_4.6.0Radio',
  props: {
    uiconfig: Object,
    pageid: String,
    dragableCss: Object
  },
  components: { BootstrapFormGroup },
  setup (props: any, context: any) {
    const radio = new Radio(props, context)

    const bodyCss = computed(() => {
      const arr: any = []
      if (props.uiconfig.meta.custom?.inline) {
        arr.push('h-100 d-flex align-items-center')
      } else {
        arr.push('h-auto')
      }
      if (props.uiconfig.meta?.css?.formSizing && props.uiconfig.meta?.css?.formSizing !== 'normal') {
        arr.push('form-control-' + props.uiconfig.meta.css.formSizing)
      }
      return arr
    })
    const bodyStyle = computed(() => {
      const style = radio.getUIStyle()
      const newStyle = {}
      for (const styleKey in style) {
        if (styleKey.match(/height/)) {
          newStyle[styleKey] = style[styleKey]
        }
      }
      return radio.appendImportant(newStyle)
    })
    return {
      ...radio.setup(),
      bodyCss,
      bodyStyle
    }
  }
}

