import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("table", {
    draggable: !_ctx.inlineEditItemId,
    class: ['table', $props.dragableCss, $setup.uiCss],
    style: $setup.uiStyle,
    id: $props.uiconfig.meta.id,
    "data-type": $props.uiconfig.type,
    "data-pageid": $props.pageid
  }, [
    (!$props.uiconfig.meta.custom?.headless)
      ? (_openBlock(), _createBlock("thead", {
          key: 0,
          style: $setup.headerStyle
        }, [
          _createVNode("tr", null, [
            (_openBlock(true), _createBlock(_Fragment, null, _renderList($setup.header, (item, index) => {
              return (_openBlock(), _createBlock("th", {
                style: $setup.headerTHStyle(index),
                class: [$setup.alignCss, $setup.headerTHCss],
                key: index,
                innerHTML: item.text
              }, null, 14, ["innerHTML"]))
            }), 128))
          ])
        ], 4))
      : _createCommentVNode("", true),
    _createVNode("tbody", {
      class: $setup.tbodyCss,
      style: $setup.tbodyStyle
    }, [
      (_openBlock(true), _createBlock(_Fragment, null, _renderList($setup.row, (r, rindex) => {
        return (_openBlock(), _createBlock("tr", { key: rindex }, [
          (_openBlock(true), _createBlock(_Fragment, null, _renderList(r, (item, cindex) => {
            return (_openBlock(), _createBlock("td", {
              class: [$setup.alignCss, $setup.tdCss],
              style: $setup.tdStyle(rindex, cindex),
              key: cindex,
              innerHTML: item.text
            }, null, 14, ["innerHTML"]))
          }), 128))
        ]))
      }), 128))
    ], 6),
    (!$props.uiconfig.meta.custom?.footless)
      ? (_openBlock(), _createBlock("tfoot", {
          key: 1,
          style: $setup.footerStyle
        }, [
          _createVNode("tr", null, [
            (_openBlock(true), _createBlock(_Fragment, null, _renderList($setup.footer, (item, index) => {
              return (_openBlock(), _createBlock("th", {
                class: [$setup.alignCss, $setup.footerTHCss],
                style: $setup.footerTHStyle(index),
                key: index,
                innerHTML: item.text
              }, null, 14, ["innerHTML"]))
            }), 128))
          ])
        ], 4))
      : _createCommentVNode("", true)
  ], 14, ["draggable", "id", "data-type", "data-pageid"]))
}