
import RangeInput from '@/components/ui/js/RangeInput'
import BootstrapFormGroup from '@/components/ui/bootstrap_4.6.0/FormGroup.vue'
import { computed } from 'vue'

export default {
  name: 'Bootstrap_4.6.0RangeInput',
  props: {
    uiconfig: Object,
    pageid: String,
    dragableCss: Object
  },
  components: { BootstrapFormGroup },
  setup (props: any, context: any) {
    const rangeinput = new RangeInput(props, context)
    const bodyCss = computed(() => {
      const arr: any = ['w-100 h-100 d-flex align-items-center mb-2']

      if (props.uiconfig.meta?.form?.state === 'disabled') {
        arr.push('disabled')
      }
      if (props.uiconfig.meta?.form?.state === 'readonly') {
        arr.push('readonly')
      }
      return arr.join(' ')
    })
    const bodyStyle = computed(() => {
      const style = rangeinput.getUIStyle()
      const newStyle = {}
      for (const styleKey in style) {
        if (styleKey.match(/height/)) {
          newStyle[styleKey] = style[styleKey]
        }
      }
      return rangeinput.appendImportant(newStyle)
    })
    const rangeCss = computed(() => {
      const css: any = ['form-control-range']
      if (props.uiconfig.meta?.css?.formSizing && props.uiconfig.meta?.css?.formSizing !== 'normal') {
        css.push('form-control-range-' + props.uiconfig.meta.css.formSizing)
      }
      if (props.uiconfig.meta?.custom?.theme && props.uiconfig.meta?.custom?.theme !== 'default') {
        css.push('range-' + props.uiconfig.meta.custom.theme)
      }
      return css.length > 0 ? css.join(' ') : ''
    })
    const rangeStyle = computed(() => {
      const style: any = []
      const background:any = []
      const backgroundSize:any = ['50%', '100%']
      const color = props.uiconfig.meta?.custom?.color
      const backgroundColor = props.uiconfig.meta?.custom?.backgroundColor
      if (color) {
        style.push(`border: 1px solid ${color} !important`)
        background.push(`-webkit-linear-gradient(${color}, ${color}) no-repeat`)
      }
      if (backgroundColor) background.push(backgroundColor)
      if (background.length > 0) style.push('background:' + background.join(',') + ' !important')

      const minValue = props.uiconfig.meta.custom?.min === undefined ? 1 : props.uiconfig.meta.custom?.min
      const defaultValue = props.uiconfig.meta.value === undefined ? 50 : props.uiconfig.meta.value
      const maxValue = props.uiconfig.meta.custom?.max === undefined ? 100 : props.uiconfig.meta.custom?.max
      backgroundSize[0] = defaultValue === 0 ? '0%' : ((defaultValue - minValue) / (maxValue - minValue) * 100) + '%'
      style.push('background-size:' + backgroundSize.join(' ') + '!important')
      // console.log(style.join(';'))
      return style.join(';')
    })
    return {
      ...rangeinput.setup(),
      bodyCss,
      bodyStyle,
      rangeCss,
      rangeStyle
    }
  }
}

