
import FormGroup from '@/components/ui/js/FormGroup'
import { computed } from 'vue'

export default {
  name: 'Bootstrap_4.6.0FromGroup',
  props: {
    uiconfig: Object,
    pageid: String,
    dragableCss: Object
  },
  setup (props: any, context: any) {
    const formGroup = new FormGroup(props, context)
    const setup = formGroup.setup()
    const uiCss = computed(() => {
      const css = formGroup.getUICss()
      delete css.formSizing
      // console.log(css)
      return Object.values(css).join(' ')
    })
    const labelCss = computed(() => {
      const css: any = ['col-form-label d-block']
      if (props.uiconfig.meta?.css?.formSizing && props.uiconfig.meta?.css?.formSizing !== 'normal') {
        css.push('col-form-label-' + props.uiconfig.meta?.css?.formSizing)
      }
      if (props.uiconfig.meta?.form?.horizontal) {
        css.push('col-' + props.uiconfig.meta?.form?.horizontalCol)
      }
      if (props.uiconfig.meta.titleAlign) {
        css.push('text-' + props.uiconfig.meta.titleAlign)
      }
      return css
    })
    const bodyCss = computed(() => {
      const css: any = ['d-flex align-items-start flex-column']
      if (props.uiconfig.meta?.form?.horizontal) {
        css.push('col-' + (12 - (props.uiconfig.meta?.form?.horizontalCol || 2)))
      }

      if (!setup.hasTitle.value && props.uiconfig.meta?.form?.horizontal) {
        css.push('offset-' + (props.uiconfig.meta?.form?.horizontalCol || 2))
      }
      return css
    })
    const uiStyle = computed(() => {
      // 部分style由包含的控件处理
      const style = formGroup.getUIStyle()
      const newStyle = {}
      for (const styleKey in style) {
        if (!styleKey.match(/height/)) {
          newStyle[styleKey] = style[styleKey]
        }
      }
      return formGroup.appendImportant(newStyle)
    })
    return {
      ...setup,
      labelCss,
      bodyCss,
      uiStyle,
      uiCss
    }
  }
}

