
import FormGroup from '@/components/ui/js/FormGroup'
import { computed } from 'vue'

export default {
  name: 'Weui_2.5.1FromGroup',
  props: {
    uiconfig: Object,
    pageid: String,
    dragableCss: Object
  },
  setup (props: any, context: any) {
    const formGroup = new FormGroup(props, context)
    const setup = formGroup.setup()
    const isHorizontal = computed(() => {
      const horizontal = props.uiconfig.meta?.form?.horizontal
      if (horizontal === undefined) return true
      return horizontal
    })
    const singleLineCss = computed(() => {
      const css: any = ['weui-cell']
      if (props.uiconfig.type === 'Select') {
        css.push('align-items-center')
      } else {
        css.push('align-items-start')
      }
      return css.join(' ')
    })
    const uiCss = computed(() => {
      const css = Object.values(formGroup.getUICss())
      if (isHorizontal.value) {
        css.push('weui-cells')
      } else {
        css.push('weui-cells__group')
      }
      if (props.uiconfig.meta?.form?.state === 'hidden') {
        css.push('hidden-preview')
      }

      if (props.uiconfig.meta?.form?.state === 'readonly' || props.uiconfig.meta?.form?.state === 'disabled') {
        css.push('weui-cell_readonly')
      }
      return css.join(' ')
    })
    const labelCss = computed(() => {
      const css: any = []
      if (isHorizontal.value) {
        css.push('weui-cell__hd pr-2')
      } else {
        css.push('weui-cells__title text-dark')
      }
      if (props.uiconfig.meta.titleAlign) {
        css.push('text-' + props.uiconfig.meta.titleAlign)
      }
      return css
    })
    const bodyCss = computed(() => {
      const css: any = ['weui-flex']
      if (props.uiconfig.type === 'Textarea') {
        css.push('align-items-start')
      } else {
        css.push('align-items-center')
      }
      return css
    })
    const uiStyle = computed(() => {
      const style = formGroup.getUIStyle()
      // 垂直排列时阴影交给内部元素处理
      if (!isHorizontal.value) {
        delete style['box-shadow']
      }
      return formGroup.appendImportant(style)
    })
    const boxStyle = computed(() => {
      const style = formGroup.getUIStyle()
      // 阴影交给内部元素处理
      return 'box-shadow:' + style['box-shadow']
    })
    return {
      ...setup,
      labelCss,
      bodyCss,
      uiStyle,
      uiCss,
      isHorizontal,
      singleLineCss,
      boxStyle
    }
  }
}

