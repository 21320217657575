
import Radio from '@/components/ui/js/Radio'
import FormGroup from '@/components/ui/weui_2.5.1/FormGroup.vue'

export default {
  name: 'Weui_2.5.1Radio',
  props: {
    uiconfig: Object,
    pageid: String,
    dragableCss: Object
  },
  components: { FormGroup },
  setup (props: any, context: any) {
    const radio = new Radio(props, context)

    return {
      ...radio.setup()
    }
  }
}

