
import Pagination from '@/components/ui/js/Pagination'
import { computed } from 'vue'
import store from '@/store'

export default {
  name: 'Weui_2.5.1Pagination',
  props: {
    uiconfig: Object,
    pageid: String,
    dragableCss: Object
  },
  setup (props: any, context: any) {
    const pagination = new Pagination(props, context)
    const uiStyle = computed(() => {
      const style = pagination.getUIStyle()
      delete style['background-color']
      delete style.color
      return pagination.appendImportant(style)
    })
    const activeLinkStyle = computed(() => {
      const style = pagination.getUIStyle()
      return style['background-color'] ? 'border-color:' + style['background-color'] + ';background-color:' + style['background-color'] : ''
    })
    const linkStyle = computed(() => {
      const style = pagination.getUIStyle()
      return style.color ? 'color:' + style.color : ''
    })
    const uiCss = computed(() => {
      const cssMap = pagination.getUICss()
      delete cssMap.backgroundTheme
      delete cssMap.foregroundTheme
      return Object.values(cssMap).join(' ')
    })
    const linkCss = computed(() => {
      const cssMap = pagination.getUICss()
      return cssMap.foregroundTheme
    })
    const activeLinkCss = computed(() => {
      const cssMap = pagination.getUICss()
      if (!cssMap.backgroundTheme) return ''
      const backgroundTheme = props.uiconfig.meta?.css?.backgroundTheme
      return cssMap.backgroundTheme + ' text-light ' + store.getters.translate('borderColorClass', backgroundTheme)
    })
    const activeItemCss = computed(() => {
      const cssMap = pagination.getUICss()
      if (!cssMap.backgroundTheme) return 'active'
      return ''
    })
    return {
      ...pagination.setup(),
      uiCss,
      uiStyle,
      linkCss,
      activeLinkCss,
      activeLinkStyle,
      linkStyle,
      activeItemCss
    }
  }
}
