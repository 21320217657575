
import Pagination from '@/components/ui/js/Pagination'

export default {
  name: 'Layui_2.6.7Pagination',
  props: {
    uiconfig: Object,
    pageid: String,
    dragableCss: Object
  },
  setup (props: any, context: any) {
    const pagination = new Pagination(props, context)
    return {
      ...pagination.setup()
    }
  }
}
