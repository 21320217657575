
import Progress from '@/components/ui/js/Progress'
import { computed } from 'vue'
import { useStore } from 'vuex'

export default {
  name: 'Weui_2.5.1Progress',
  props: {
    uiconfig: Object,
    pageid: String,
    dragableCss: Object
  },
  setup (props: any, context: any) {
    const progress = new Progress(props, context)
    const setup = progress.setup()
    const store = useStore()
    const uiCss = computed(() => {
      const cssMap = progress.getUICss()
      delete cssMap.foregroundTheme
      return Object.values(cssMap).join(' ')
    })
    const themeCss = computed(() => {
      return store.getters.translate('backgroundTheme', props.uiconfig.meta?.css?.foregroundTheme)
    })
    const uiStyle = computed(() => {
      const style = progress.getUIStyle()
      delete style?.color
      return progress.appendImportant(style)
    })
    const themeStyle = computed(() => {
      const style: any = ['width:' + setup.defaultValue.value + '% !important;']
      const uiStyle = progress.getUIStyle()

      // color 作为前景div的background color
      if (uiStyle?.color) {
        style.push('background-color:' + uiStyle.color + ' !important;')
      }
      // console.log(style)
      return style.join(';')
    })
    return {
      ...setup,
      uiCss,
      uiStyle,
      themeCss,
      themeStyle
    }
  }
}

