import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withKeys as _withKeys } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconWrapper = _resolveComponent("IconWrapper")

  return (_openBlock(), _createBlock("div", {
    class: [$props.dragableCss,$setup.uiCss,$props.uiconfig.meta.custom?.direction||'dropdown',$setup.cssAsChild],
    draggable: !_ctx.inlineEditItemId,
    style: $setup.uiStyle,
    id: $props.uiconfig.meta.id,
    "data-type": $props.uiconfig.type,
    "data-pageid": $props.pageid,
    onDblclick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.inlineEditItemId=$props.uiconfig.meta.id)),
    onKeyup: _cache[2] || (_cache[2] = _withKeys(($event: any) => (_ctx.inlineEditItemId=''), ["enter"]))
  }, [
    ($props.uiconfig.meta.custom?.isSplit)
      ? (_openBlock(), _createBlock(_Fragment, { key: 0 }, [
          _createVNode("a", {
            class: [$setup.btnCss],
            id: $props.uiconfig.meta.id+'MenuLink',
            type: "button",
            style: $setup.btnStyle,
            contenteditable: _ctx.inlineEditItemId==$props.uiconfig.meta.id
          }, [
            _createVNode(_component_IconWrapper, { uiconfig: $props.uiconfig }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString($props.uiconfig.meta.title || 'Dropdown'), 1)
              ]),
              _: 1
            }, 8, ["uiconfig"])
          ], 14, ["id", "contenteditable"]),
          _createVNode("a", {
            class: ['dropdown-toggle dropdown-toggle-split', $setup.splitBtnCss],
            style: $setup.btnStyle
          }, null, 6)
        ], 64))
      : (_openBlock(), _createBlock("a", {
          key: 1,
          class: ['dropdown-toggle', $setup.btnCss],
          style: $setup.btnStyle,
          role: "button",
          id: $props.uiconfig.meta.id+'MenuLink',
          type: "button",
          contenteditable: _ctx.inlineEditItemId==$props.uiconfig.meta.id
        }, [
          _createVNode(_component_IconWrapper, { uiconfig: $props.uiconfig }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString($props.uiconfig.meta.title || 'Dropdown'), 1)
            ]),
            _: 1
          }, 8, ["uiconfig"])
        ], 14, ["id", "contenteditable"]))
  ], 46, ["draggable", "id", "data-type", "data-pageid"]))
}