import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, createVNode as _createVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BootstrapFormGroup = _resolveComponent("BootstrapFormGroup")

  return (_openBlock(), _createBlock(_component_BootstrapFormGroup, {
    uiconfig: $props.uiconfig,
    pageid: $props.pageid,
    draggable: !_ctx.inlineEditItemId,
    dragableCss: $props.dragableCss
  }, {
    default: _withCtx(() => [
      _createVNode("div", {
        class: $setup.bodyCss,
        style: $setup.bodyStyle
      }, [
        (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.values, (item, index) => {
          return (_openBlock(), _createBlock("div", {
            key: index,
            class: {'form-check': true, 'form-check-inline': $props.uiconfig.meta.custom?.inline}
          }, [
            _createVNode("input", {
              type: "radio",
              checked: item.checked,
              class: "form-check-input",
              id: $props.uiconfig.meta.id+item.value,
              disabled: $props.uiconfig.meta?.form?.state==='disabled',
              required: $props.uiconfig.meta?.form?.required,
              readonly: $props.uiconfig.meta?.form?.state==='readonly',
              value: item.value,
              name: $props.uiconfig.meta?.form?.inputName
            }, null, 8, ["checked", "id", "disabled", "required", "readonly", "value", "name"]),
            _createVNode("label", {
              class: "form-check-label",
              for: $props.uiconfig.meta.id+item.value
            }, _toDisplayString(item.text), 9, ["for"])
          ], 2))
        }), 128))
      ], 6)
    ]),
    _: 1
  }, 8, ["uiconfig", "pageid", "draggable", "dragableCss"]))
}