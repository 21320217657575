import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UIBase = _resolveComponent("UIBase")

  return (_openBlock(), _createBlock("div", {
    draggable: !_ctx.inlineEditItemId,
    class: ['nav',$props.dragableCss, $setup.uiCss, $setup.navTypeCss,
      { 'nav-justified': $props.uiconfig.meta.custom?.justified,
      'nav-fill': $props.uiconfig.meta.custom?.filled,
      'card-header-tabs': _ctx.parentIsCard && $props.uiconfig.meta.custom?.type==='tab',
      'card-header-pills': _ctx.parentIsCard && $props.uiconfig.meta.custom?.type==='pill'}],
    style: $setup.uiStyle,
    id: $props.uiconfig.meta.id,
    "data-type": $props.uiconfig.type,
    "data-isContainer": true,
    "data-pageid": $props.pageid
  }, [
    (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.values, (item, index) => {
      return (_openBlock(), _createBlock("div", {
        class: [{'nav-item pointer-event-none': true}],
        key: index
      }, [
        _createVNode("a", {
          class: [{'nav-link':true}, (item.checked ? $setup.activeItemCss : $setup.itemCss)],
          style: item.checked ? $setup.activeItemStyle : $setup.itemStyle,
          href: "#"
        }, _toDisplayString(item.text), 7)
      ]))
    }), 128)),
    (_openBlock(true), _createBlock(_Fragment, null, _renderList($props.uiconfig.items, (item, index) => {
      return (_openBlock(), _createBlock(_component_UIBase, {
        key: index,
        uiconfig: item,
        pageid: $props.pageid
      }, null, 8, ["uiconfig", "pageid"]))
    }), 128))
  ], 14, ["draggable", "id", "data-type", "data-pageid"]))
}