
import Page from '@/components/ui/js/Page'
import UIBase from '@/components/ui/UIBase.vue'
import { computed } from 'vue'

export default {
  name: 'Layui_2.6.7Page',
  components: { UIBase },
  props: {
    uiconfig: Object,
    pageid: String,
    dragableCss: Object
  },
  setup (props: any, context: any) {
    const page = new Page(props, context)
    const uiCss = computed(() => {
      const css = page.getUICss()
      if (props.uiconfig.items) {
        let hasForm: any = false
        for (const item of props.uiconfig.items) {
          // console.log(item)
          if (item.meta?.form) {
            hasForm = true
            break
          }
        }
        if (hasForm) {
          css.form = 'layui-form'
        }
      }
      return Object.values(css).join(' ')
    })
    return {
      ...page.setup(),
      uiCss
    }
  }
}

