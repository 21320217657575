
import UIBase from '@/components/ui/UIBase.vue'
import Card from '../js/Card'
import { computed } from 'vue'

export default {
  name: 'Layui_2.6.7Card',
  components: { UIBase },
  props: {
    uiconfig: Object,
    pageid: String,
    dragableCss: Object
  },
  setup (props: any, context: any) {
    const card = new Card(props, context)
    const cardHeadCss = computed(() => {
      const arr: any = []
      let hasTab: boolean = false
      if (props.uiconfig.items) {
        for (const item of props.uiconfig.items) {
          if (item.type.toLowerCase() === 'nav' && item.meta.custom?.type === 'tab') {
            hasTab = true
            break
          }
        }
      }
      if (!hasTab) {
        arr.push('layui-card-header')
      }
      return arr.join(' ')
    })
    return {
      ...card.setup(),
      cardHeadCss
    }
  }
}
