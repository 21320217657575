import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, createVNode as _createVNode, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "layui-anim layui-icon"
}
const _hoisted_2 = {
  key: 1,
  class: "layui-anim layui-icon layui-anim-scaleSpring"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LayuiFormGroup = _resolveComponent("LayuiFormGroup")

  return (_openBlock(), _createBlock(_component_LayuiFormGroup, {
    uiconfig: $props.uiconfig,
    pageid: $props.pageid,
    draggable: !_ctx.inlineEditItemId,
    dragableCss: $props.dragableCss
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.values, (item, index) => {
        return (_openBlock(), _createBlock("div", {
          key: index,
          class: {'layui-d-inline-block': $props.uiconfig.meta.custom?.inline}
        }, [
          _createVNode("div", {
            class: {'layui-unselect layui-form-radio': true, 'layui-form-radioed': item.checked, 'layui-radio-disabled layui-disabled':$props.uiconfig.meta?.form?.state==='disabled'}
          }, [
            (!item.checked)
              ? (_openBlock(), _createBlock("i", _hoisted_1, ""))
              : _createCommentVNode("", true),
            (item.checked)
              ? (_openBlock(), _createBlock("i", _hoisted_2, ""))
              : _createCommentVNode("", true),
            _createVNode("span", null, _toDisplayString(item.text), 1)
          ], 2)
        ], 2))
      }), 128))
    ]),
    _: 1
  }, 8, ["uiconfig", "pageid", "draggable", "dragableCss"]))
}