
import BootstrapFormGroup from '@/components/ui/bootstrap_4.6.0/FormGroup.vue'
import File from '@/components/ui/js/File'
import { computed } from 'vue'

export default {
  name: 'Bootstrap_4.6.0File',
  props: {
    uiconfig: Object,
    pageid: String,
    dragableCss: Object
  },
  components: { BootstrapFormGroup },
  setup (props: any, context: any) {
    const file = new File(props, context)
    const bodyCss = computed(() => {
      const arr: any = ['d-flex align-items-center']
      if (props.uiconfig.meta?.css?.formSizing && props.uiconfig.meta?.css?.formSizing !== 'normal') {
        arr.push('form-control-' + props.uiconfig.meta.css.formSizing)
      }
      if (props.uiconfig.meta?.form?.state === 'disabled') {
        arr.push('disabled')
      }
      if (props.uiconfig.meta?.form?.state === 'readonly') {
        arr.push('readonly')
      }
      return arr.join(' ')
    })
    const bodyStyle = computed(() => {
      const style = file.getUIStyle()
      const newStyle = {}
      for (const styleKey in style) {
        if (styleKey.match(/height/)) {
          newStyle[styleKey] = style[styleKey]
        }
      }
      return file.appendImportant(newStyle)
    })
    return {
      ...file.setup(),
      bodyStyle,
      bodyCss
    }
  }
}

