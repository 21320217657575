import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { href: "javascript:;" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("nav", {
    draggable: !_ctx.inlineEditItemId,
    class: ['layui-breadcrumb', $setup.uiCss, $props.dragableCss],
    style: $setup.uiStyle,
    id: $props.uiconfig.meta.id,
    "data-type": $props.uiconfig.type,
    "data-pageid": $props.pageid
  }, [
    (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.values, (item, index) => {
      return (_openBlock(), _createBlock(_Fragment, { key: index }, [
        _createVNode("a", _hoisted_1, [
          (!item.checked)
            ? (_openBlock(), _createBlock("span", {
                key: 0,
                class: $setup.foreTheme,
                style: $setup.foreStyle
              }, _toDisplayString(item.text), 7))
            : _createCommentVNode("", true),
          (item.checked)
            ? (_openBlock(), _createBlock("cite", {
                key: 1,
                style: $setup.activeStyle
              }, _toDisplayString(item.text), 5))
            : _createCommentVNode("", true)
        ]),
        (index!==_ctx.values.length-1)
          ? (_openBlock(), _createBlock("span", {
              key: 0,
              "lay-separator": "/",
              class: $setup.foreTheme,
              style: $setup.foreStyle
            }, "/", 6))
          : _createCommentVNode("", true)
      ], 64))
    }), 128))
  ], 14, ["draggable", "id", "data-type", "data-pageid"]))
}