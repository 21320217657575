
import Image from '@/components/ui/js/Image'

export default {
  name: 'Bootstrap_4.6.0Image',
  props: {
    uiconfig: Object,
    pageid: String,
    dragableCss: Object
  },
  setup (props: any, context: any) {
    const image = new Image(props, context)
    return {
      ...image.setup()
    }
  }
}

