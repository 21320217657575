
import BootstrapFormGroup from '@/components/ui/layui_2.6.7/FormGroup.vue'
import Checkbox from '@/components/ui/js/Checkbox'

export default {
  name: 'Layui_2.6.7Checkbox',
  props: {
    uiconfig: Object,
    pageid: String,
    dragableCss: Object
  },
  components: { BootstrapFormGroup },
  setup (props: any, context: any) {
    const checkbox = new Checkbox(props, context)
    return {
      ...checkbox.setup()
    }
  }
}

