import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("ul", {
    draggable: !_ctx.inlineEditItemId,
    class: [$props.dragableCss, $setup.uiCss,'layui-list-group'],
    style: $setup.uiStyle,
    id: $props.uiconfig.meta.id,
    "data-type": $props.uiconfig.type,
    "data-pageid": $props.pageid
  }, [
    (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.values, (item, index) => {
      return (_openBlock(), _createBlock("li", {
        class: [{'layui-list-group-item': true}, $setup.itemClass],
        style: `${$setup.itemStyle};${item.checked ? $setup.activeItemStyle : ''}`,
        key: index,
        value: item.value
      }, _toDisplayString(item.text), 15, ["value"]))
    }), 128))
  ], 14, ["draggable", "id", "data-type", "data-pageid"]))
}