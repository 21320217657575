

import { computed } from 'vue'
import UIBase from '@/components/ui/UIBase.vue'
import Navbar from '@/components/ui/js/Navbar'
import ydhl from '@/lib/ydhl'

export default {
  name: 'Bootstrap_4.6.0Navbar',
  components: { UIBase },
  props: {
    uiconfig: Object,
    pageid: String,
    dragableCss: Object
  },
  setup (props: any, context: any) {
    const navbar = new Navbar(props, context)
    const setup = navbar.setup()
    const uiCss = computed(() => {
      const css = navbar.getUICss()
      delete css.foregroundTheme
      return Object.values(css).join(' ')
    })
    const uiStyle = computed(() => {
      const style = navbar.getUIStyle()
      delete style.color
      return navbar.appendImportant(style)
    })
    const itemCss = computed(() => {
      const css = navbar.getUICss()
      return css.foregroundTheme
    })
    const itemStyle = computed(() => {
      const style = navbar.getUIStyle()
      return `color:${style.color} !important`
    })
    const activeItemStyle = computed(() => {
      const style = navbar.getUIStyle()
      if (!style.color) return
      const rgba = ydhl.getRgbaInfo(style.color)
      rgba.a *= 0.7
      return `color:rgba(${rgba.r},${rgba.g},${rgba.b},${rgba.a}) !important`
    })
    return {
      ...setup,
      uiCss,
      uiStyle,
      itemCss,
      itemStyle,
      activeItemStyle
    }
  }
}

