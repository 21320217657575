import { toDisplayString as _toDisplayString, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", {
    class: ['layui-progress', $props.dragableCss, $setup.uiCss],
    draggable: !_ctx.inlineEditItemId,
    style: $setup.uiStyle,
    id: $props.uiconfig.meta.id,
    "data-type": $props.uiconfig.type,
    "data-pageid": $props.pageid
  }, [
    _createVNode("div", {
      class: $setup.barCss,
      style: $setup.barStyle,
      "lay-percent": _ctx.defaultValue + '%'
    }, [
      ($props.uiconfig.meta.custom?.label)
        ? (_openBlock(), _createBlock("div", _hoisted_1, _toDisplayString(_ctx.defaultValue) + "%", 1))
        : _createCommentVNode("", true)
    ], 14, ["lay-percent"])
  ], 14, ["draggable", "id", "data-type", "data-pageid"]))
}