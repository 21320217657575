import { vModelText as _vModelText, createVNode as _createVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "ml-3"
}
const _hoisted_2 = {
  key: 1,
  class: "cursor ml-3"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BootstrapFormGroup = _resolveComponent("BootstrapFormGroup")

  return (_openBlock(), _createBlock(_component_BootstrapFormGroup, {
    uiconfig: $props.uiconfig,
    pageid: $props.pageid,
    draggable: !_ctx.inlineEditItemId,
    dragableCss: $props.dragableCss
  }, {
    default: _withCtx(() => [
      _createVNode("div", {
        class: $setup.bodyCss,
        style: $setup.bodyStyle
      }, [
        _withDirectives(_createVNode("textarea", {
          class: "w-100 border-0",
          style: $props.uiconfig.meta.custom?.autoRow ? 'resize: none' : '',
          id: $props.uiconfig.meta.id+$props.uiconfig.type,
          name: $props.uiconfig.meta?.form?.inputName,
          disabled: $props.uiconfig.meta?.form?.state==='disabled',
          readonly: $props.uiconfig.meta?.form?.state==='readonly',
          required: $props.uiconfig.meta?.form?.required,
          placeholder: $props.uiconfig.meta?.form?.placeholder,
          rows: $props.uiconfig.meta.custom?.row,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.defaultValue = $event))
        }, null, 12, ["id", "name", "disabled", "readonly", "required", "placeholder", "rows"]), [
          [_vModelText, _ctx.defaultValue]
        ]),
        ($props.uiconfig.meta?.custom?.wordCountVisible)
          ? (_openBlock(), _createBlock("div", _hoisted_1, "0" + _toDisplayString($props.uiconfig.meta?.custom?.maxLength ? '/' + $props.uiconfig.meta?.custom?.maxLength : ''), 1))
          : _createCommentVNode("", true),
        ($props.uiconfig.meta?.custom?.clearButtonVisible)
          ? (_openBlock(), _createBlock("div", _hoisted_2, "×"))
          : _createCommentVNode("", true)
      ], 6)
    ]),
    _: 1
  }, 8, ["uiconfig", "pageid", "draggable", "dragableCss"]))
}