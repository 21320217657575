
import Radio from '@/components/ui/js/Radio'
import LayuiFormGroup from '@/components/ui/layui_2.6.7/FormGroup.vue'

export default {
  name: 'Layui_2.6.7Radio',
  props: {
    uiconfig: Object,
    pageid: String,
    dragableCss: Object
  },
  components: { LayuiFormGroup },
  setup (props: any, context: any) {
    const radio = new Radio(props, context)
    return {
      ...radio.setup()
    }
  }
}

