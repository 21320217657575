import { createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", {
    draggable: !_ctx.inlineEditItemId,
    class: [$props.dragableCss, $setup.uiCss, 'layui-d-flex layui-justify-content-center layui-align-items-center'],
    style: $setup.uiStyle,
    id: $props.uiconfig.meta.id,
    "data-type": $props.uiconfig.type,
    "data-pageid": $props.pageid
  }, [
    _createVNode("div", {
      class: ['layui-flex-grow-1', $setup.lineCss],
      style: $setup.lineStyle
    }, null, 6),
    ($props.uiconfig.meta.value)
      ? (_openBlock(), _createBlock("div", {
          key: 0,
          class: ['layui-flex-shrink-0 layui-pl-2 layui-pr-2', $setup.textCss],
          style: $setup.textStyle
        }, _toDisplayString($props.uiconfig.meta.value), 7))
      : _createCommentVNode("", true),
    _createVNode("div", {
      class: ['layui-flex-grow-1', $setup.lineCss],
      style: $setup.lineStyle
    }, null, 6)
  ], 14, ["draggable", "id", "data-type", "data-pageid"]))
}