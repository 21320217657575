
import FormGroup from '@/components/ui/js/FormGroup'
import { computed } from 'vue'

export default {
  name: 'Layui_2.6.7FromGroup',
  props: {
    uiconfig: Object,
    pageid: String,
    dragableCss: Object
  },
  setup (props: any, context: any) {
    const formGroup = new FormGroup(props, context)
    const setup = formGroup.setup()
    const uiCss = computed(() => {
      const css = formGroup.getUICss()
      delete css.formSizing

      if (props.uiconfig.meta?.form?.state === 'disabled') {
        css.disabled = 'layui-disabled'
      }
      // console.log(css)
      return Object.values(css).join(' ')
    })
    const labelCss = computed(() => {
      const css: any = []

      if (props.uiconfig.meta?.form?.horizontal) {
        css.push('layui-form-mid')
        css.push('layui-col-xs' + props.uiconfig.meta?.form?.horizontalCol)
      } else {
        css.push('layui-form-label-col')
      }
      return css
    })

    const labelStyle = computed(() => {
      const styles: any = []
      if (props.uiconfig.meta?.form?.horizontal) {
        styles.push('margin-right: 0px !important;padding-right: 15px !important;')
      }

      if (props.uiconfig.meta.titleAlign) {
        styles.push('text-align:' + props.uiconfig.meta.titleAlign)
      }
      return styles.join(';')
    })

    const bodyCss = computed(() => {
      const css: any = []
      if (props.uiconfig.meta?.form?.horizontal) {
        css.push('layui-col-xs' + (12 - (props.uiconfig.meta?.form?.horizontalCol || 2)))
      }
      // console.log(setup.hasTitle)
      if (!setup.hasTitle.value && props.uiconfig.meta?.form?.horizontal) {
        css.push('layui-col-xs-offset' + (props.uiconfig.meta?.form?.horizontalCol || 2))
      }
      return css
    })
    const uiStyle = computed(() => {
      // 部分style由包含的控件处理
      const style = formGroup.getUIStyle()
      const newStyle = {}
      for (const styleKey in style) {
        if (!styleKey.match(/height/)) {
          newStyle[styleKey] = style[styleKey]
        }
      }
      return formGroup.appendImportant(newStyle)
    })
    return {
      ...setup,
      labelCss,
      labelStyle,
      bodyCss,
      uiStyle,
      uiCss
    }
  }
}

