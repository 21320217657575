
import UIBase from '@/components/ui/js/UIBase'

export default class FormGroup extends UIBase {
  setup () {
    return {
      ...super.setup()
    }
  }
}
