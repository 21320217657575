
import Textarea from '@/components/ui/js/Textarea'
import LayuiFormGroup from '@/components/ui/layui_2.6.7/FormGroup.vue'
import { computed } from 'vue'

export default {
  name: 'Layui_2.6.7Textarea',
  props: {
    uiconfig: Object,
    pageid: String,
    dragableCss: Object
  },
  components: { LayuiFormGroup },
  setup (props: any, context: any) {
    const textarea = new Textarea(props, context)
    const bodyCss = computed(() => {
      const arr = ['layui-textarea layui-d-flex layui-align-items-end']

      if (props.uiconfig.meta?.form?.state === 'disabled') {
        arr.push('layui-disabled')
      }
      if (props.uiconfig.meta?.form?.state === 'readonly') {
        arr.push('layui-disabled')
      }
      return arr
    })
    const bodyStyle = computed(() => {
      const style = textarea.getUIStyle()
      const newStyle = {}
      for (const styleKey in style) {
        if (styleKey.match(/height/)) {
          newStyle[styleKey] = style[styleKey]
        }
      }
      return textarea.appendImportant(newStyle)
    })

    return {
      ...textarea.setup(),
      bodyStyle,
      bodyCss
    }
  }
}

