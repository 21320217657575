
import UIBase from '@/components/ui/UIBase.vue'
import Container from '@/components/ui/js/Container'

export default {
  name: 'Weui_2.5.1Container',
  components: { UIBase },
  props: {
    uiconfig: Object,
    pageid: String,
    dragableCss: Object
  },
  setup (props: any, context: any) {
    const container = new Container(props, context)
    return {
      ...container.setup()
    }
  }
}

