import { toDisplayString as _toDisplayString, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "text-muted"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UIBase = _resolveComponent("UIBase")

  return (_openBlock(), _createBlock("div", {
    class: ['weui-btn_group', $setup.buttonGroupCss, $props.dragableCss],
    style: $setup.buttonGroupStyle,
    role: "group",
    draggable: !_ctx.inlineEditItemId,
    "data-isContainer": true,
    id: $props.uiconfig.meta.id,
    "data-type": $props.uiconfig.type,
    "data-pageid": $props.pageid
  }, [
    (!$props.uiconfig.items || $props.uiconfig.items.length==0)
      ? (_openBlock(), _createBlock("div", _hoisted_1, _toDisplayString(_ctx.t('ui.buttonGroupEmptyTip', [$setup.allowItem])), 1))
      : _createCommentVNode("", true),
    (_openBlock(true), _createBlock(_Fragment, null, _renderList($props.uiconfig.items, (item, index) => {
      return (_openBlock(), _createBlock(_component_UIBase, {
        key: index,
        uiconfig: item,
        pageid: $props.pageid
      }, null, 8, ["uiconfig", "pageid"]))
    }), 128))
  ], 14, ["draggable", "id", "data-type", "data-pageid"]))
}