
import Select from '@/components/ui/js/Select'
import FormGroup from '@/components/ui/weui_2.5.1/FormGroup.vue'
import { computed } from 'vue'

export default {
  name: 'Weui_2.5.1Select',
  props: {
    uiconfig: Object,
    pageid: String,
    dragableCss: Object
  },
  components: { FormGroup },
  setup (props: any, context: any) {
    const select = new Select(props, context)
    const bodyCss = computed(() => {
      const css = select.getUICss()
      const arr: any = ['weui-select pl-0']
      if (css.foregroundTheme) arr.push(css.foregroundTheme)
      return arr
    })
    const bodyStyle = computed(() => {
      const style: any = {}
      const baseStyle = select.getUIStyle()
      style['border-top'] = '1px solid #e5e5e5'
      style['border-bottom'] = '1px solid #e5e5e5'
      if (baseStyle?.color) style.color = baseStyle?.color
      return select.appendImportant(style)
    })
    return {
      ...select.setup(),
      bodyCss,
      bodyStyle
    }
  }
}

