
import Icon from '@/components/ui/js/Icon.ts'
import { computed } from 'vue'

export default {
  name: 'Bootstrap_4.6.0Icon',
  props: {
    uiconfig: Object,
    pageid: String,
    dragableCss: Object
  },
  setup (props: any, context: any) {
    const icon = new Icon(props, context)
    const iconClass = computed(() => props.uiconfig.meta?.custom?.icon)
    return {
      ...icon.setup(),
      iconClass
    }
  }
}

