import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withKeys as _withKeys, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconWrapper = _resolveComponent("IconWrapper")

  return ($setup.buttonType=='link')
    ? (_openBlock(), _createBlock("a", {
        key: 0,
        draggable: !_ctx.inlineEditItemId,
        class: [$setup.btnCss, $props.dragableCss],
        style: $setup.btnStyle,
        id: $props.uiconfig.meta.id,
        "data-type": $props.uiconfig.type,
        "data-isContainer": false,
        href: "#",
        onDblclick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.inlineEditItemId=$props.uiconfig.meta.id)),
        onKeyup: _cache[2] || (_cache[2] = _withKeys(($event: any) => (_ctx.inlineEditItemId=''), ["enter"])),
        contenteditable: _ctx.inlineEditItemId==$props.uiconfig.meta.id,
        "data-pageid": $props.pageid
      }, [
        _createVNode(_component_IconWrapper, { uiconfig: $props.uiconfig }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString($props.uiconfig.meta.title), 1)
          ]),
          _: 1
        }, 8, ["uiconfig"])
      ], 46, ["draggable", "id", "data-type", "contenteditable", "data-pageid"]))
    : (_openBlock(), _createBlock("button", {
        key: 1,
        draggable: !_ctx.inlineEditItemId,
        class: [$setup.btnCss, $props.dragableCss],
        style: $setup.btnStyle,
        id: $props.uiconfig.meta.id,
        "data-type": $props.uiconfig.type,
        "data-pageid": $props.pageid,
        "data-isContainer": false,
        onDblclick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.inlineEditItemId=$props.uiconfig.meta.id)),
        onKeyup: _cache[4] || (_cache[4] = _withKeys(($event: any) => (_ctx.inlineEditItemId=''), ["enter"])),
        contenteditable: _ctx.inlineEditItemId==$props.uiconfig.meta.id,
        type: $setup.buttonType
      }, [
        _createVNode(_component_IconWrapper, { uiconfig: $props.uiconfig }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString($props.uiconfig.meta.title), 1)
          ]),
          _: 1
        }, 8, ["uiconfig"])
      ], 46, ["draggable", "id", "data-type", "data-pageid", "contenteditable", "type"]))
}