
import { computed } from 'vue'
import UIBase from '@/components/ui/js/UIBase'

export default class Navbar extends UIBase {
  setup () {
    const setup = super.setup()
    const props = this.props
    const values = computed(() => {
      if (!props.uiconfig.meta.values || props.uiconfig.meta.values.length === 0) {
        return [{ text: 'Sample 1', value: '#' }, { text: 'Sample 2', value: '#', checked: true }]
      }
      return props.uiconfig.meta.values
    })
    const myItems = computed(() => {
      const items:any = { right: [], main: [], brand: [] }
      if (!props.uiconfig.items) return items
      for (const item of props.uiconfig.items) {
        if (item.placeInParent === 'brand') {
          items.brand.push(item)
        } else if (item.placeInParent === 'right') {
          items.right.push(item)
        } else {
          items.main.push(item)
        }
      }
      return items
    })

    return {
      ...setup,
      myItems,
      values
    }
  }
}
