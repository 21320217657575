import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withKeys as _withKeys, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconWrapper = _resolveComponent("IconWrapper")

  return ($props.uiconfig.meta.custom?.isSplit)
    ? (_openBlock(), _createBlock("div", {
        key: 0,
        class: ['layui-d-inline-block', $setup.uiCss, $props.dragableCss],
        contenteditable: _ctx.inlineEditItemId==$props.uiconfig.meta.id,
        draggable: !_ctx.inlineEditItemId,
        style: $setup.uiStyle,
        id: $props.uiconfig.meta.id,
        "data-type": $props.uiconfig.type,
        "data-pageid": $props.pageid,
        onDblclick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.inlineEditItemId=$props.uiconfig.meta.id)),
        onKeyup: _cache[2] || (_cache[2] = _withKeys(($event: any) => (_ctx.inlineEditItemId=''), ["enter"]))
      }, [
        _createVNode("span", {
          class: ['layui-rounded-left', $setup.btnCss],
          style: $setup.btnStyle,
          contenteditable: _ctx.inlineEditItemId==$props.uiconfig.meta.id
        }, [
          _createVNode(_component_IconWrapper, { uiconfig: $props.uiconfig }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString($props.uiconfig.meta.title || 'Dropdown'), 1)
            ]),
            _: 1
          }, 8, ["uiconfig"])
        ], 14, ["contenteditable"]),
        _createVNode("span", {
          class: [$setup.splitBtnCss, $setup.btnCss],
          style: $setup.splitBtnStyle
        }, [
          _createVNode("i", {
            class: {'layui-icon layui-font-12': true,
          'layui-icon-up': $props.uiconfig.meta.custom?.direction=='dropup',
          'layui-icon-down': $props.uiconfig.meta.custom?.direction=='dropdown' || !$props.uiconfig.meta.custom?.direction,
          'layui-icon-left': $props.uiconfig.meta.custom?.direction=='dropleft',
          'layui-icon-right': $props.uiconfig.meta.custom?.direction=='dropright'}
          }, null, 2)
        ], 6)
      ], 46, ["contenteditable", "draggable", "id", "data-type", "data-pageid"]))
    : (_openBlock(), _createBlock("div", {
        key: 1,
        class: [$setup.uiCss, $props.dragableCss, $setup.btnCss],
        contenteditable: _ctx.inlineEditItemId==$props.uiconfig.meta.id,
        draggable: !_ctx.inlineEditItemId,
        style: $setup.uiStyle,
        id: $props.uiconfig.meta.id,
        "data-type": $props.uiconfig.type,
        "data-pageid": $props.pageid,
        onDblclick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.inlineEditItemId=$props.uiconfig.meta.id)),
        onKeyup: _cache[4] || (_cache[4] = _withKeys(($event: any) => (_ctx.inlineEditItemId=''), ["enter"]))
      }, [
        _createVNode(_component_IconWrapper, { uiconfig: $props.uiconfig }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString($props.uiconfig.meta.title || 'Dropdown'), 1)
          ]),
          _: 1
        }, 8, ["uiconfig"]),
        _createVNode("i", {
          class: {'layui-icon layui-font-12': true,
          'layui-icon-up': $props.uiconfig.meta.custom?.direction=='dropup',
          'layui-icon-down': $props.uiconfig.meta.custom?.direction=='dropdown' || !$props.uiconfig.meta.custom?.direction,
          'layui-icon-left': $props.uiconfig.meta.custom?.direction=='dropleft',
          'layui-icon-right': $props.uiconfig.meta.custom?.direction=='dropright'}
        }, null, 2)
      ], 46, ["contenteditable", "draggable", "id", "data-type", "data-pageid"]))
}