
import Progress from '@/components/ui/js/Progress'
import { computed } from 'vue'
import { useStore } from 'vuex'

export default {
  name: 'Bootstrap_4.6.0Progress',
  props: {
    uiconfig: Object,
    pageid: String,
    dragableCss: Object
  },
  setup (props: any, context: any) {
    const progress = new Progress(props, context)
    const setup = progress.setup()
    const store = useStore()
    const uiCss = computed(() => {
      const cssMap = progress.getUICss()
      delete cssMap.foregroundTheme
      return Object.values(cssMap).join(' ')
    })
    const uiStyle = computed(() => {
      const style = progress.getUIStyle()
      delete style.color
      return progress.appendImportant(style)
    })
    const barCss = computed(() => {
      return store.getters.translate('backgroundTheme', props.uiconfig.meta?.css?.foregroundTheme)
    })
    const barStyle = computed(() => {
      const style: any = ['width:' + setup.defaultValue.value + '%']
      const uiStyle = progress.getUIStyle()

      if (uiStyle?.color) {
        style.push('background-color:' + uiStyle.color + ' !important')
      }
      return style.join(';')
    })
    return {
      ...setup,
      uiCss,
      uiStyle,
      barCss,
      barStyle
    }
  }
}

