
import Text from '@/components/ui/js/Text'
import { computed } from 'vue'

export default {
  name: 'Layui_2.6.7Text',
  props: {
    uiconfig: Object,
    pageid: String,
    dragableCss: Object
  },
  setup (props: any, context: any) {
    const text = new Text(props, context)
    const setup = text.setup()
    const myCss = computed(() => {
      const arr: any = Object.values(text.getUICss())
      if (props.uiconfig.meta.custom?.align) {
        arr.push('layui-text-' + props.uiconfig.meta.custom?.align)
      }
      if (props.uiconfig.meta.custom?.italic) {
        arr.push('layui-font-italic')
      }
      if (props.uiconfig.meta.custom?.bold) {
        arr.push('layui-font-weight-' + props.uiconfig.meta.custom?.bold.toLowerCase())
      }
      return arr
    })
    const myStyleText = computed(() => {
      const style = text.appendImportant(text.getUIStyle())
      const arr: any = []
      const decoration: any = []
      if (props.uiconfig.meta.custom?.underline) {
        decoration.push('underline')
      }
      if (props.uiconfig.meta.custom?.through) {
        decoration.push('line-through')
      }
      if (decoration.length > 0) {
        arr.push('text-decoration:' + decoration.join(' '))
      }
      if (props.uiconfig.meta.custom?.lineHeight) {
        arr.push('line-height:' + props.uiconfig.meta.custom?.lineHeight)
      }
      return arr.length > 0 ? style + ';' + arr.join(';') : style
    })
    return {
      myCss,
      myStyleText,
      ...setup
    }
  }
}

