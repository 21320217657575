
import UIBase from '@/components/ui/UIBase.vue'
import Container from '@/components/ui/js/Container'
import { computed } from 'vue'

export default {
  name: 'Layui_2.6.7Container',
  components: { UIBase },
  props: {
    uiconfig: Object,
    pageid: String,
    dragableCss: Object
  },
  setup (props: any, context: any) {
    const container = new Container(props, context)
    const uiCss = computed(() => {
      const css = container.getUICss()
      if (props.uiconfig.items) {
        let hasForm: any = false
        for (const item of props.uiconfig.items) {
          if (item.meta?.form) {
            hasForm = true
            break
          }
        }
        if (hasForm) {
          css.form = 'layui-form'
        }
      }
      return Object.values(css).join(' ')
    })
    return {
      ...container.setup(),
      uiCss
    }
  }
}

