import { createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "ml-3"
}
const _hoisted_2 = {
  key: 1,
  class: "cursor ml-3"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconWrapper = _resolveComponent("IconWrapper")
  const _component_BootstrapFormGroup = _resolveComponent("BootstrapFormGroup")

  return (_openBlock(), _createBlock(_component_BootstrapFormGroup, {
    uiconfig: $props.uiconfig,
    pageid: $props.pageid,
    draggable: !_ctx.inlineEditItemId,
    dragableCss: $props.dragableCss
  }, {
    default: _withCtx(() => [
      _createVNode("div", {
        class: $setup.bodyCss,
        style: $setup.bodyStyle
      }, [
        _createVNode(_component_IconWrapper, { uiconfig: $props.uiconfig }, {
          default: _withCtx(() => [
            _createVNode("input", {
              type: $props.uiconfig.meta?.custom?.inputType || 'Text',
              id: $props.uiconfig.meta.id+$props.uiconfig.type,
              class: "w-100 border-0",
              name: $props.uiconfig.meta?.form?.inputName,
              placeholder: $props.uiconfig.meta?.form?.placeholder,
              disabled: $props.uiconfig.meta?.form?.state==='disabled',
              readonly: $props.uiconfig.meta?.form?.state==='readonly',
              required: $props.uiconfig.meta?.form?.required,
              value: $props.uiconfig.meta.value
            }, null, 8, ["type", "id", "name", "placeholder", "disabled", "readonly", "required", "value"])
          ]),
          _: 1
        }, 8, ["uiconfig"]),
        ($props.uiconfig.meta?.custom?.wordCountVisible)
          ? (_openBlock(), _createBlock("div", _hoisted_1, "0" + _toDisplayString($props.uiconfig.meta?.custom?.maxLength ? '/' + $props.uiconfig.meta?.custom?.maxLength : ''), 1))
          : _createCommentVNode("", true),
        ($props.uiconfig.meta?.custom?.clearButtonVisible)
          ? (_openBlock(), _createBlock("div", _hoisted_2, "×"))
          : _createCommentVNode("", true)
      ], 6)
    ]),
    _: 1
  }, 8, ["uiconfig", "pageid", "draggable", "dragableCss"]))
}