import { createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "weui-uploader__bd" }
const _hoisted_2 = { class: "weui-uploader__input-box" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormGroup = _resolveComponent("FormGroup")

  return (_openBlock(), _createBlock(_component_FormGroup, {
    uiconfig: $props.uiconfig,
    pageid: $props.pageid,
    draggable: !_ctx.inlineEditItemId,
    dragableCss: $props.dragableCss
  }, {
    default: _withCtx(() => [
      _createVNode("div", _hoisted_1, [
        _createVNode("div", _hoisted_2, [
          _createVNode("input", {
            id: $props.uiconfig.meta.id+$props.uiconfig.type,
            disabled: $props.uiconfig.meta?.form?.state==='disabled',
            name: $props.uiconfig.meta?.form?.inputName,
            class: "weui-uploader__input",
            type: "file"
          }, null, 8, ["id", "disabled", "name"])
        ])
      ])
    ]),
    _: 1
  }, 8, ["uiconfig", "pageid", "draggable", "dragableCss"]))
}