
import UIBase from '@/components/ui/UIBase.vue'
import Nav from '@/components/ui/js/Nav'
import { computed } from 'vue'
import { useStore } from 'vuex'

export default {
  name: 'Layui_2.6.7Nav',
  components: { UIBase },
  props: {
    uiconfig: Object,
    pageid: String,
    dragableCss: Object
  },
  setup (props: any, context: any) {
    const nav = new Nav(props, context)
    const store = useStore()
    const pillCheckedTheme = computed(() => {
      const css: any = []
      const uicss = nav.getUICss()
      if (uicss.foregroundTheme) {
        css.push(store.getters.translate('backgroundTheme', props.uiconfig.meta.css?.foregroundTheme))
      }
      return css.length > 0 ? css.join(' ') : ''
    })

    const foreTheme = computed(() => {
      const css = nav.getUICss()
      return css.foregroundTheme
    })
    const foreStyle = computed(() => {
      const style = nav.getUIStyle()
      return style.color ? `color: ${style.color} !important` : ''
    })
    const uiCss = computed(() => {
      const css = nav.getUICss()
      delete css.foregroundTheme
      // console.log(props.uiconfig.meta.custom)
      if (props.uiconfig.meta.custom?.type === 'pill') {
        css.pill = 'layui-d-flex'
        if (props.uiconfig.meta.custom?.justified) {
          css.justified = 'layui-nav-justified'
        }
        if (props.uiconfig.meta.custom?.filled) {
          css.filled = 'layui-nav-fill'
        }
      }
      return Object.values(css).join(' ')
    })
    const tabBodyCss = computed(() => {
      const css = ['layui-tab-title']
      if (props.uiconfig.meta.custom?.justified) {
        css.push('layui-nav-justified')
      }

      if (props.uiconfig.meta.custom?.filled) {
        css.push('layui-nav-fill')
      }
      return css.join(' ')
    })
    return {
      ...nav.setup(),
      uiCss,
      pillCheckedTheme,
      foreStyle,
      tabBodyCss,
      foreTheme
    }
  }
}

