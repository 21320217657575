
import LayuiFormGroup from '@/components/ui/layui_2.6.7/FormGroup.vue'
import Input from '@/components/ui/js/Input'
import { computed } from 'vue'
import IconWrapper from '@/components/ui/bootstrap_4.6.0/IconWrapper.vue'

export default {
  name: 'Layui_2.6.7Input',
  components: { IconWrapper, LayuiFormGroup },
  props: {
    uiconfig: Object,
    pageid: String,
    dragableCss: Object
  },
  setup (props: any, context: any) {
    const input = new Input(props, context)
    const bodyCss = computed(() => {
      const arr: any = ['layui-input layui-pl-1 layui-pr-1 layui-d-flex layui-align-items-center']
      if (props.uiconfig.meta?.css?.formSizing && props.uiconfig.meta?.css?.formSizing !== 'normal') {
        arr.push('layui-form-' + props.uiconfig.meta.css.formSizing)
      }
      if (props.uiconfig.meta?.form?.state === 'disabled') {
        arr.push('disabled')
      }
      if (props.uiconfig.meta?.form?.state === 'readonly') {
        arr.push('readonly')
      }
      return arr
    })
    const bodyStyle = computed(() => {
      const style = input.getUIStyle()
      const newStyle = {}
      for (const styleKey in style) {
        if (styleKey.match(/height/)) {
          newStyle[styleKey] = style[styleKey]
        }
      }
      return input.appendImportant(newStyle)
    })
    return {
      ...input.setup(),
      bodyCss,
      bodyStyle
    }
  }
}

