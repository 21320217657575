
import RangeInput from '@/components/ui/js/RangeInput'
import FormGroup from '@/components/ui/weui_2.5.1/FormGroup.vue'
import { computed } from 'vue'
import { useStore } from 'vuex'

export default {
  name: 'Weui_2.5.1RangeInput',
  props: {
    uiconfig: Object,
    pageid: String,
    dragableCss: Object
  },
  components: { FormGroup },
  setup (props: any, context: any) {
    const rangeinput = new RangeInput(props, context)
    const store = useStore()
    /**
     * 滑块主题样式
     */
    const handleStyle = computed(() => {
      const style: any = []
      style.push(`left: ${props.uiconfig.meta.value || 50}%; !important;`)
      if (props.uiconfig.meta?.custom?.color) {
        style.push('background-color:' + props.uiconfig.meta?.custom?.color + ' !important;')
      }
      return style.join(';')
    })
    const handleTheme = computed(() => {
      const css: any = ['weui-slider__handler weui-wa-hotarea']
      if (props.uiconfig.meta?.custom?.theme && props.uiconfig.meta?.custom?.theme !== 'default') {
        css.push(store.getters.translate('backgroundTheme', props.uiconfig.meta?.custom?.theme))
      }
      return css.join(' ')
    })
    /**
     * 前景色样式，已滑动距离
     */
    const trackTheme = computed(() => {
      const css: any = ['weui-slider__track']
      if (props.uiconfig.meta?.custom?.theme && props.uiconfig.meta?.custom?.theme !== 'default') {
        css.push(store.getters.translate('backgroundTheme', props.uiconfig.meta?.custom?.theme))
      }
      return css.join(' ')
    })
    const trackStyle = computed(() => {
      const style: any = [`width: ${props.uiconfig.meta.value || 50}% !important;`]
      if (props.uiconfig.meta?.custom?.color) {
        style.push('background-color:' + props.uiconfig.meta?.custom?.color + ' !important;')
      }
      return style.join(';')
    })
    /**
     * 背景色样式，底色
     */
    const bgTheme = computed(() => {
      const css: any = ['weui-slider__inner']
      return css.join(' ')
    })
    const bgStyle = computed(() => {
      const backgroundColor = props.uiconfig.meta?.custom?.backgroundColor
      if (backgroundColor) return 'background-color: ' + backgroundColor + ' !important;'
    })

    return {
      ...rangeinput.setup(),
      bgTheme,
      bgStyle,
      trackTheme,
      trackStyle,
      handleStyle,
      handleTheme
    }
  }
}

