
import UIBase from '@/components/ui/UIBase.vue'
import Modal from '../js/Modal'
import { computed, ref } from 'vue'

export default {
  name: 'Bootstrap_4.6.0Model',
  components: { UIBase },
  props: {
    uiconfig: Object,
    pageid: String,
    dragableCss: Object
  },
  setup (props: any, context: any) {
    const modal = new Modal(props, context)
    const setup = modal.setup()
    const mainItems = ref<Array<any>>([])
    const hasMainItems = computed(() => setup.myItems.value.main.length > 0)
    for (const item of setup.myItems.value.main) {
      mainItems.value.push(item)
    }
    const uiStyle = computed(() => {
      const style = modal.getUIStyle()
      for (const styleKey in style) {
        if (styleKey.match(/^border|^outline/)) {
          delete style[styleKey]
        }
      }
      return modal.appendImportant(style)
    })
    const uiCss = computed(() => {
      const css = modal.getUICss()
      delete css.backgroundTheme
      delete css.foregroundTheme
      return Object.values(css).join(' ')
    })
    const bodyClass = computed(() => {
      const cssMap = modal.getUICss()
      const css: any = ['modal-content shadow']
      css.push(cssMap.backgroundTheme)
      css.push(cssMap.foregroundTheme)
      return css.join(' ')
    })
    const bodyStyle = computed(() => {
      const style = modal.getUIStyle()
      const newStyle = {}
      for (const styleKey in style) {
        if (styleKey.match(/^border|^outline/)) {
          newStyle[styleKey] = style[styleKey]
        }
      }
      return modal.appendImportant(newStyle)
    })
    return {
      mainItems,
      hasMainItems,
      ...setup,
      uiCss,
      uiStyle,
      bodyClass,
      bodyStyle
    }
  }
}
