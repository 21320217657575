import { toDisplayString as _toDisplayString, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", {
    draggable: !_ctx.inlineEditItemId,
    style: $setup.uiStyle,
    id: $props.uiconfig.meta.id,
    "data-type": $props.uiconfig.type,
    "data-pageid": $props.pageid,
    class: ['layui-d-flex layui-align-items-center',$props.dragableCss, $setup.uiCss,{'layui-form-item': $props.uiconfig.meta?.form?.horizontal, 'hidden-preview':$props.uiconfig.meta?.form?.state==='hidden'}]
  }, [
    (_ctx.hasTitle)
      ? (_openBlock(), _createBlock("label", {
          key: 0,
          for: $props.uiconfig.meta.id+$props.uiconfig.type,
          class: $setup.labelCss,
          style: $setup.labelStyle
        }, _toDisplayString($props.uiconfig.meta.title), 15, ["for"]))
      : _createCommentVNode("", true),
    _createVNode("div", { class: $setup.bodyCss }, [
      _renderSlot(_ctx.$slots, "default"),
      (_ctx.hasHelpTip && $props.uiconfig.meta?.form?.horizontal)
        ? (_openBlock(), _createBlock("small", {
            key: 0,
            id: $props.uiconfig.meta.id+'Help',
            class: "layui-form-mid layui-word-aux"
          }, _toDisplayString($props.uiconfig.meta?.form?.helpTip), 9, ["id"]))
        : _createCommentVNode("", true)
    ], 2),
    (_ctx.hasHelpTip && !$props.uiconfig.meta?.form?.horizontal)
      ? (_openBlock(), _createBlock("small", {
          key: 1,
          id: $props.uiconfig.meta.id+'Help',
          class: "layui-form-mid layui-word-aux"
        }, _toDisplayString($props.uiconfig.meta?.form?.helpTip), 9, ["id"]))
      : _createCommentVNode("", true)
  ], 14, ["draggable", "id", "data-type", "data-pageid"]))
}