

import { computed } from 'vue'
import UIBase from '@/components/ui/UIBase.vue'
import Navbar from '@/components/ui/js/Navbar'

export default {
  name: 'Layui_2.6.7Navbar',
  components: { UIBase },
  props: {
    uiconfig: Object,
    pageid: String,
    dragableCss: Object
  },
  setup (props: any, context: any) {
    const navbar = new Navbar(props, context)
    const setup = navbar.setup()
    const menuCss = computed(() => {
      const cssMap = navbar.getUICss()
      return cssMap.foregroundTheme
    })
    const menuStyle = computed(() => {
      const styleMap = navbar.getUIStyle()
      return `color:${styleMap.color}`
    })

    return {
      ...setup,
      menuCss,
      menuStyle
    }
  }
}

