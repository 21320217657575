
import UIBase from '@/components/ui/UIBase.vue'
import Card from '../js/Card'
import { computed, ref } from 'vue'

export default {
  name: 'Weui_2.5.1Card',
  components: { UIBase },
  props: {
    uiconfig: Object,
    pageid: String,
    dragableCss: Object
  },
  setup (props: any, context: any) {
    const card = new Card(props, context)
    const setup = card.setup()
    const mainItems = ref<Array<any>>([])
    const otherMainItems = ref<Array<any>>([])
    const hasMainItems = computed(() => setup.myItems.value.main.length > 0)
    for (const item of setup.myItems.value.main) {
      if (item.type.toLowerCase() === 'list' || item.type.toLowerCase() === 'table') {
        otherMainItems.value.push(item)
      } else {
        mainItems.value.push(item)
      }
    }
    return {
      mainItems,
      otherMainItems,
      hasMainItems,
      ...setup
    }
  }
}
