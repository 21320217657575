
import UIBase from '@/components/ui/UIBase.vue'
import Modal from '../js/Modal'
import { computed, ref } from 'vue'

export default {
  name: 'Weui_2.5.1Model',
  components: { UIBase },
  props: {
    uiconfig: Object,
    pageid: String,
    dragableCss: Object
  },
  setup (props: any, context: any) {
    const modal = new Modal(props, context)
    const setup = modal.setup()
    const mainItems = ref<Array<any>>([])
    const hasMainItems = computed(() => setup.myItems.value.main.length > 0)
    for (const item of setup.myItems.value.main) {
      mainItems.value.push(item)
    }
    const uiStyle = computed(() => {
      const style = modal.getUIStyle()
      return modal.appendImportant(style)
    })
    return {
      mainItems,
      hasMainItems,
      ...setup,
      uiStyle
    }
  }
}
