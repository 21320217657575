
import Page from '@/components/ui/js/Page'
import UIBase from '@/components/ui/UIBase.vue'
import { computed } from 'vue'

export default {
  name: 'Bootstrap_4.6.0Page',
  components: { UIBase },
  props: {
    uiconfig: Object,
    pageid: String,
    dragableCss: Object
  },
  setup (props: any, context: any) {
    const page = new Page(props, context)
    const uiStyle = computed(() => {
      const style = page.getUIStyle()
      if (props.uiconfig.pageType === 'popup') {
        style.display = 'flex'
        style['justify-content'] = 'center'
        style['align-items'] = 'stretch'
      }
      return page.appendImportant(style)
    })
    return {
      ...page.setup(),
      uiStyle
    }
  }
}

