import { createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return ($setup.iconClass)
    ? (_openBlock(), _createBlock("i", {
        key: 0,
        draggable: !_ctx.inlineEditItemId,
        class: [ $props.dragableCss, _ctx.uiCss, $setup.iconClass],
        style: _ctx.uiStyle,
        id: $props.uiconfig.meta.id,
        "data-type": $props.uiconfig.type,
        "data-pageid": $props.pageid
      }, null, 14, ["draggable", "id", "data-type", "data-pageid"]))
    : (_openBlock(), _createBlock("div", {
        key: 1,
        draggable: !_ctx.inlineEditItemId,
        class: [ $props.dragableCss, _ctx.uiCss],
        style: _ctx.uiStyle,
        id: $props.uiconfig.meta.id,
        "data-type": $props.uiconfig.type,
        "data-pageid": $props.pageid
      }, _toDisplayString(_ctx.t('style.icon.notChooseIconTip')), 15, ["draggable", "id", "data-type", "data-pageid"]))
}