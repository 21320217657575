import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "layui-card-body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UIBase = _resolveComponent("UIBase")

  return (_openBlock(), _createBlock("div", {
    class: [$props.dragableCss, _ctx.uiCss, 'layui-card'],
    draggable: !_ctx.inlineEditItemId,
    style: _ctx.uiStyle,
    id: $props.uiconfig.meta.id,
    "data-type": $props.uiconfig.type,
    "data-isContainer": true,
    "data-pageid": $props.pageid
  }, [
    (!$props.uiconfig.meta.custom?.headless)
      ? (_openBlock(), _createBlock("div", {
          key: 0,
          class: ['subui', $setup.cardHeadCss, {'dragenter-subcontainer': _ctx.isDragIn && _ctx.dragoverInParent=='head'}],
          "data-placeInParent": "head"
        }, [
          (!_ctx.myItems.head.length)
            ? (_openBlock(), _createBlock(_Fragment, { key: 0 }, [
                _createTextVNode(_toDisplayString(_ctx.t('style.card.drapTip')), 1)
              ], 64))
            : _createCommentVNode("", true),
          (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.myItems.head, (item, index) => {
            return (_openBlock(), _createBlock(_component_UIBase, {
              key: index,
              uiconfig: item,
              pageid: $props.pageid
            }, null, 8, ["uiconfig", "pageid"]))
          }), 128))
        ], 2))
      : _createCommentVNode("", true),
    _createVNode("div", _hoisted_1, [
      (!_ctx.myItems.main.length)
        ? (_openBlock(), _createBlock(_Fragment, { key: 0 }, [
            _createTextVNode(_toDisplayString(_ctx.t('style.card.drapTip')), 1)
          ], 64))
        : _createCommentVNode("", true),
      (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.myItems.main, (item, index) => {
        return (_openBlock(), _createBlock(_component_UIBase, {
          key: index,
          uiconfig: item,
          pageid: $props.pageid
        }, null, 8, ["uiconfig", "pageid"]))
      }), 128))
    ]),
    (!$props.uiconfig.meta.custom?.footless)
      ? (_openBlock(), _createBlock("div", {
          key: 1,
          class: ['layui-card-footer subui',{'dragenter-subcontainer': _ctx.isDragIn && _ctx.dragoverInParent=='foot'}],
          "data-placeInParent": "foot"
        }, [
          (!_ctx.myItems.foot.length)
            ? (_openBlock(), _createBlock(_Fragment, { key: 0 }, [
                _createTextVNode(_toDisplayString(_ctx.t('style.card.drapTip')), 1)
              ], 64))
            : _createCommentVNode("", true),
          (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.myItems.foot, (item, index) => {
            return (_openBlock(), _createBlock(_component_UIBase, {
              key: index,
              uiconfig: item,
              pageid: $props.pageid
            }, null, 8, ["uiconfig", "pageid"]))
          }), 128))
        ], 2))
      : _createCommentVNode("", true)
  ], 14, ["draggable", "id", "data-type", "data-pageid"]))
}