
import FormGroup from '@/components/ui/weui_2.5.1/FormGroup.vue'
import File from '@/components/ui/js/File'

export default {
  name: 'Weui_2.5.1File',
  props: {
    uiconfig: Object,
    pageid: String,
    dragableCss: Object
  },
  components: { FormGroup },
  setup (props: any, context: any) {
    const file = new File(props, context)
    return {
      ...file.setup()
    }
  }
}

