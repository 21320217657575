
import Image from '@/components/ui/js/Image'
import { computed } from 'vue'

export default {
  name: 'Weui_2.5.1Image',
  props: {
    uiconfig: Object,
    pageid: String,
    dragableCss: Object
  },
  setup (props: any, context: any) {
    const image = new Image(props, context)
    const uiStyle = computed(() => {
      const style = image.getUIStyle()
      if (!props.uiconfig.meta?.style?.width) {
        style.width = '100%'
      }
      return image.appendImportant(style)
    })
    return {
      ...image.setup(),
      uiStyle
    }
  }
}

