import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", {
    class: ['progress', $props.dragableCss, $setup.uiCss],
    draggable: !_ctx.inlineEditItemId,
    style: $setup.uiStyle,
    id: $props.uiconfig.meta.id,
    "data-type": $props.uiconfig.type,
    "data-pageid": $props.pageid
  }, [
    _createVNode("div", {
      class: ['progress-bar', $setup.barCss,
      {'progress-bar-striped':$props.uiconfig.meta.custom?.striped,'progress-bar-animated':$props.uiconfig.meta.custom?.animatedStrip}],
      role: "progressbar",
      style: $setup.barStyle,
      "aria-valuenow": _ctx.defaultValue,
      "aria-valuemin": $props.uiconfig.meta.custom?.min,
      "aria-valuemax": $props.uiconfig.meta.custom?.max
    }, [
      ($props.uiconfig.meta.custom?.label)
        ? (_openBlock(), _createBlock(_Fragment, { key: 0 }, [
            _createTextVNode(_toDisplayString(_ctx.defaultValue) + "%", 1)
          ], 64))
        : _createCommentVNode("", true)
    ], 14, ["aria-valuenow", "aria-valuemin", "aria-valuemax"])
  ], 14, ["draggable", "id", "data-type", "data-pageid"]))
}